import * as React from 'react'
import { connect, useDispatch } from 'react-redux'
import parse from 'html-react-parser'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import variables from '../../theme/variables.scss'
import { Link } from 'react-router-dom'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import IconButton from '@mui/material/IconButton'
import { Typography } from '@mui/material'
import Loading from '../Loading'
import './../../../src/util/screenflow-data.scss'
import { toggleEligibilityCriteriaModal } from '../../redux/modals/toggleModal.actions'
import { getTrialCriteriaData_v1 } from '../../redux/searchFlow/screenFlow/screenFlow.action'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: variables.gray50,
    color: variables.gray500,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  verticalAlign: 'top',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: variables.gray50,
    // backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  // borderBottom: `1px solid ${variables.gray50}`,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: variables.purple,
  '&:hover': {
    color: variables.purple,
    textDecoration: 'underline !important',
  },
}))

export const ClinicalTrialTable = ({
  login,
  token,
  tableHeaderData,
  tableBodyData,
  togglePopUp,
  setTogglePopUp,
  loading,
}) => {
  const dispatch = useDispatch()

  return (
    <TableContainer
      component={Paper}
      sx={{
        fontSize: '14px',
        borderRadius: '11px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        minHeight: 'calc(100vH - 250px)',
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHeaderData?.map((header, index) => {
              return (
                <StyledTableCell
                  //  align={header?.align ?? 'left'}
                  key={index}
                >
                  {header.name}
                </StyledTableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell sx={{ border: 'none !important' }} colSpan={4}>
                <Box className="d-flex flex-column align-items-center justify-content-center">
                  <Loading />
                  <Typography className="gray-600 fw-normal fs-7 pb-2">Fetching Trial Data...</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {!!tableBodyData && tableBodyData.length > 0 ? (
                tableBodyData?.map((row) => (
                  <StyledTableRow key={row.trial_name}>
                    <StyledTableCell width={'280'} component="th" scope="row">
                      <StyledLink
                        to={`/screenflow-step-2?trial_id=${row?.id}&clinic_id=${row.clinic_id}`}
                        /* add set Trial function */
                      >
                        <Box as="span" className="fw-bold text-capitalize">
                          {row?.trial_name ?? row?.name}{' '}
                        </Box>
                        {!!row?.titleInfo ? `: ${row?.titleInfo}` : ''}
                      </StyledLink>
                    </StyledTableCell>

                    <StyledTableCell sx={{ color: variables.black200, fontWeight: '600' }} width={'180'}>
                      {row?.sponsor}
                    </StyledTableCell>
                    <StyledTableCell width={'310'}>
                      {parse(row?.elibilitycriteria ?? 'No Criteria Available')}

                      <Box
                        sx={{
                          px: '1.2rem',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          aria-label="open-popup"
                          onClick={() => {
                            // dispatch(
                            //     setEligibilityCriteria({
                            //         name: row?.name,
                            //         inclusionCriteria: row?.inclusionCriteria,
                            //         exclusionCriteria: row?.exclusionCriteria,
                            //         inclusionNo:
                            //             row?.inclusionCriteria?.length ?? 0,

                            //         exclusionNo:
                            //             row?.exclusionCriteria?.length ?? 0,
                            //     })
                            // )
                            dispatch(getTrialCriteriaData_v1(row?.id, row?.clinic_id, token))
                            dispatch(toggleEligibilityCriteriaModal(true))
                          }}
                          edge={'start'}
                          disableRipple
                        >
                          <TextSnippetIcon
                            sx={{
                              color: variables.indigo500,
                              cursor: 'pointer',
                            }}
                          />
                          <Typography
                            sx={{
                              color: variables.indigo500,
                            }}
                            className="fs-9 fw-bold px-1"
                          >
                            Show more
                          </Typography>
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* {!!(typeof row?.arm === 'string') ? parse(row?.arm) : row?.arm} */}
                      {parse(row?.arm)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell sx={{ border: 'none !important' }} colSpan={4}>
                    <Typography
                      sx={{
                        color: variables.black200,
                        // fontWeight: '600',
                        textAlign: 'center',
                        fontSize: '14px',
                        padding: '20px',
                      }}
                    >
                      No Clinical Trials Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => ({
  login: state.loginData.loginResponse,
  token: state.loginData.loginResponse.idToken,
})

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEligibilityCriteriaModal: (payload) => dispatch(toggleEligibilityCriteriaModal(payload)),
    getTrialCriteriaData_v1: (payload, token) => dispatch(getTrialCriteriaData_v1(payload, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClinicalTrialTable)
