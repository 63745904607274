/* eslint-disable no-unused-vars */
import './App.scss'
import AccountDeactivated from './components/account-deactivated'
import ChangePassword from './components/authentications/change-password'
import EcgModal from './components/ecg-component/ecg-modal'
import EchoModal from './components/ESN/echo-component/echo-modal'
import StressEchoModal from './components/ESN/stress-echo-component/stress-echo-modal/index'
import NuclearModal from './components/ESN/nuclear-component/nuclear-modal'
import CathModal from './components/CCM/cath-component/cath-modal'
import GxtModal from './components/gxt-component/gxt-modal'
import HolterModal from './components/holter-component/holter-modal'
import Home from './pages/home'
import LabsModal from './components/labs-component/labs-modal'
import MuiAlert from '@mui/material/Alert'
import React, { useEffect } from 'react'
import ResetPassword from './components/authentications/reset-password'
import SessionExpired from './components/session-expired'
import Snackbar from '@mui/material/Snackbar'
import VitalModal from './components/vital-component/vital-modal'
import awsExports from './aws-config'
import { Auth, Amplify, Hub } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { loginComponents, loginFormFields } from './pages/login/cognito'
import { removeAlert, setAlert } from './redux/alert/alert.action'
import { toggleSessionModal } from './redux/modals/toggleModal.actions'
import CtModal from './components/CCM/ct-component/ct-modal'
import MriModal from './components/CCM/mri-component/mri-modal'
import CardiacModal from './components/medications/cardiac-component/cardiac-modal'
import SocialModal from './components/social-history/social-modal'
import FamilyModal from './components/family-history/family-modal'
import ConsultModal from './components/consult-notes/consult-modal'
import AdverseEventModal from './components/adverse-event/adverse-modal'
import IntervalModal from './components/interval-history/interval-modal'
import PhysicalModal from './components/physical-exam/physical-modal'
import MedicalHistoryModal from './components/medical-history/medical-modal/index'
import CardiacHistoryModal from './components/cardiac-history/cardiac-modal/index'
import CardiacTimelineModal from './components/cardiac-history/cardiac-timeline-modal/index'
import RecommendationsModal from './components/recommendations/recommendations-modal/RecommendationsModal'
import ConsultLetterModal from './components/consult-letter/consult-letter-modal/index'
import ScreenFlowModal from './components/screen-flow/screen-flow-modal'
import EligibilityCriteriaModal from './components/portal-components/EligibilityCriteriaModal'
import AppointmentListModal from './components/portal-components/AppointmentListModal'
import PatientInfoModal from './components/portal-components/PatientInfoModal'
import { login, loginClear } from './redux/auth/auth.action'
import axios from 'axios'
import Login from './pages/login/login'
import TranscribeProvider from './context/awsTranscribe'

import PortalDeck from './pages/portal-deck/PortalDeck'
import ScreenFlow from './pages/ScreenFlow'
import NotFound from './pages/NotFound'
import Step2 from './pages/Step2'
import Step3 from './pages/Step3'
import CohortBuilderStep1 from './pages/cohort-builder/CohortBuilderStep1'
import CohortBuilderStep2 from './pages/cohort-builder/CohortBuilderStep2'
import CohortBuilderStep3 from './pages/cohort-builder/CohortBuilderStep3'

const STATUS_CODES = {
    OK: 200,
    BAD_REQUEST: 400,
}

Amplify.configure(awsExports)
const App = () => {
    let routes = useRoutes([
        { path: '/', element: <Login /> },
        /* { path: '/home', element: <Home /> }, */ //sunset wrokflow
        { path: '/portals', element: <PortalDeck /> },
        { path: '/portals/searchflow', element: <PortalDeck /> },
        { path: '/screenflow', element: <ScreenFlow /> },
        { path: '/screenflow-step-2', element: <Step2 /> },
        { path: '/screenflow-step-3', element: <Step3 /> },
        { path: '/cohort-builder-step-1', element: <CohortBuilderStep1 /> },
        { path: '/cohort-builder-step-2', element: <CohortBuilderStep2 /> },
        { path: '/cohort-builder-step-3', element: <CohortBuilderStep3 /> },

        { path: '*', element: <NotFound /> },
    ])

    return routes
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const AppWrapper = ({
    vitalsModal,
    alert,
    labsModal,
    ecgModal,
    holterModal,
    gxtModal,
    echoModal,
    stressEchoModal,
    nuclearModal,
    cathModal,
    ctModal,
    mriModal,
    cardiacModal,
    socialModal,
    familyModal,
    consultModal,
    adverseEventModal,
    intervalModal,
    physicalModal,
    medicalHistoryModal,
    cardiacHistoryModal,
    cardiacTimelineModal,
    recommendationsModal,
    consultLetterModal,
    screenFlowModal,
    eligibilityCriteriaModal,
    appointmentModal,
    patientInfoModal,
}) => {
    const dispatch = useDispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showAlert = (alertType, alertMsg) => {
        dispatch(
            setAlert({
                alertType,
                alertMsg,
                alertOpen: true,
            })
        )
    }
    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        dispatch(removeAlert())
    }

    useEffect(() => {
        const listener = async (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    // User has signed in, update Redux store
                    dispatch(loginClear())
                    const { signInUserSession } = data.payload.data
                    const jwtToken = signInUserSession.getAccessToken().getJwtToken()
                    const idToken = signInUserSession.getIdToken().getJwtToken()

                    const doLogin = async () => {
                        try {
                            const headers = {
                                accessToken: jwtToken,
                                Authorization: `Bearer ${idToken}`,
                            }
                            const response = await axios.post(
                                `${process.env.REACT_APP_SCREENFLOW_BASE_URL}/${process.env.REACT_APP_ENVIRONMENT}/api/login`,
                                null,
                                { headers }
                            )
                            if (response?.status === STATUS_CODES.OK) {
                                dispatch(login(response?.data))
                            } else if (response.data.status >= STATUS_CODES.BAD_REQUEST) {
                                showAlert('error', response?.data?.message)
                            } else {
                                showAlert('error', 'Something went wrong! Please try again.')
                            }
                        } catch (err) {
                            showAlert('error', 'Something went wrong! Please try again.')
                            console.error(err)
                        }
                    }

                    doLogin().catch(console.error)

                    break
                default:
                    console.log(`Unhandled event: ${data.payload.event}`)
                    break
            }
        }

        const removeListener = Hub.listen('auth', listener)

        return () => {
            // Clean up listener when component is unmounted
            removeListener()
        }
    }, [dispatch, showAlert])

    return (
        <Authenticator formFields={loginFormFields} components={loginComponents}>
            <Router>
                <TranscribeProvider>
                    <App />
                    <ResetPassword />
                    <SessionExpired />
                    <AccountDeactivated />
                    <ChangePassword />
                    {vitalsModal && <VitalModal />}
                    {labsModal && <LabsModal />}
                    {ecgModal && <EcgModal />}
                    {holterModal && <HolterModal />}
                    {gxtModal && <GxtModal />}
                    {echoModal && <EchoModal />}
                    {stressEchoModal && <StressEchoModal />}
                    {nuclearModal && <NuclearModal />}
                    {cathModal && <CathModal />}
                    {ctModal && <CtModal />}
                    {mriModal && <MriModal />}
                    {cardiacModal && <CardiacModal />}
                    {socialModal && <SocialModal />}
                    {familyModal && <FamilyModal />}
                    {consultModal && <ConsultModal />}
                    {adverseEventModal && <AdverseEventModal />}
                    {intervalModal && <IntervalModal />}
                    {physicalModal && <PhysicalModal />}
                    {medicalHistoryModal && <MedicalHistoryModal />}
                    {cardiacHistoryModal && <CardiacHistoryModal />}
                    {cardiacTimelineModal && <CardiacTimelineModal />}
                    {recommendationsModal && <RecommendationsModal />}
                    {consultLetterModal && <ConsultLetterModal />}
                    {screenFlowModal && <ScreenFlowModal />}
                    {eligibilityCriteriaModal && <EligibilityCriteriaModal />}
                    {appointmentModal && <AppointmentListModal />}
                    {patientInfoModal && <PatientInfoModal />}
                    <Snackbar
                        disableWindowBlurListener={true}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={alert?.alertOpen}
                        autoHideDuration={4000}
                        onClose={handleMessageClose}
                    >
                        <Alert
                            onClose={handleMessageClose}
                            severity={alert?.alertType}
                            sx={{ width: '100%' }}
                        >
                            {alert?.alertMsg}
                        </Alert>
                    </Snackbar>
                </TranscribeProvider>
            </Router>
        </Authenticator>
    )
}

const mapStateToProps = (state) => {
    return {
        patientProfileModal: state.toggleModals.patientProfileModal,
        token: state.loginData.loginResponse.accessToken,
        reduxAppointmentData: state.appointment.appointmentData,
        maxData: state.appointment.appointmentData.resultCount,
        vitalsModal: state.toggleModals.vitalsModal,
        labsModal: state.toggleModals.labsModal,
        ecgModal: state.toggleModals.ecgModal,
        holterModal: state.toggleModals.holterModal,
        gxtModal: state.toggleModals.gxtModal,
        echoModal: state.toggleModals.echoModal,
        stressEchoModal: state.toggleModals.stressEchoModal,
        nuclearModal: state.toggleModals.nuclearModal,
        cathModal: state.toggleModals.cathModal,
        ctModal: state.toggleModals.ctModal,
        mriModal: state.toggleModals.mriModal,
        cardiacModal: state.toggleModals.cardiacModal,
        socialModal: state.toggleModals.socialModal,
        familyModal: state.toggleModals.familyModal,
        consultModal: state.toggleModals.consultModal,
        adverseEventModal: state.toggleModals.adverseEventModal,
        intervalModal: state?.toggleModals?.intervalModal,
        physicalModal: state?.toggleModals?.physicalModal,
        medicalHistoryModal: state?.toggleModals?.medicalHistoryModal,
        cardiacHistoryModal: state?.toggleModals?.cardiacHistoryModal,
        cardiacTimelineModal: state?.toggleModals?.cardiacTimelineModal,
        recommendationsModal: state?.toggleModals?.recommendationsModal,
        consultLetterModal: state?.toggleModals?.consultLetterModal,
        screenFlowModal: state?.toggleModals?.screenFlowModal,
        eligibilityCriteriaModal: state?.toggleModals?.eligibilityCriteriaModal,
        appointmentModal: state?.toggleModals?.appointmentModal,
        patientInfoModal: state?.toggleModals?.patientInfoModal,
        loading: state.appointment.loading,
        alert: state.alert,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        removeAlert: () => dispatch(removeAlert()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
