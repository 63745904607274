import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import variables from '../../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import BackspaceIcon from '@mui/icons-material/Backspace'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FormComponent from './form-components/FromComponent'
import {
    cohortCategories,
    cohortLabsData,
    cohortMedicalHxData,
    cohortCardiacHxData,
    cohortMedicationData,
    cohortDemographyData,
    cohortComparisonOperators,
    cohortCriteriaOperators,
    CohortLinkOperators,
} from '../../../../util/formData'
import { setCohortBuilderFormData } from '../../../../redux/searchFlow/cohortBuilder/cohortBuilder.action'
import { setAlert } from '../../../../redux/alert/alert.action'
import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const CohortForm = ({ cohortBuilderFormData }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const categoryMapping = (categoryName) => {
        switch (categoryName) {
            case 'Medical History':
                return cohortMedicalHxData
            case 'Cardiac History':
                return cohortCardiacHxData
            case 'Labs':
                return cohortLabsData
            case 'Medication':
                return cohortMedicationData
            case 'Demographics':
                return cohortDemographyData
            default:
                return []
        }
    }

    /* to add id to each criterion */
    // const addIdHandler = (arr) => {
    //     return arr.map((item, index) => {
    //         return { ...item, id: index + 1 }
    //     })
    // }

    /* validation */
    const validationSchema = {}

    const onSubmitHandler = (values) => {
        // function to check for missing criterionOperator
        const hasMissingCriterionOperator = (criterion) => {
            if (criterion?.criterionValues?.length > 1) {
                return criterion?.criterionValues?.some((criterionValue, idx) => {
                    return idx < criterion?.criterionValues?.length - 1 && !criterionValue?.criterionOperator
                })
            }
            return false
        }

        // check each criterion for missing criterionOperator
        const criteriaOperatorFlag = values?.criteria?.some(hasMissingCriterionOperator)

        if (criteriaOperatorFlag) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: (
                        <Box>
                            <strong>Error:</strong> Missing Sub-Criterion Linking Operator.
                            <br />
                            Please select a criterion operator (AND / OR) from the dropdown menu
                            <br />
                            between the linking sub-criteria within each criterion card.
                        </Box>
                    ),
                    alertOpen: true,
                })
            )
            return
        }

        // if no missing criterionOperator, proceed with navigation and dispatch
        navigate(`/cohort-builder-step-2`)
        dispatch(setCohortBuilderFormData(values))
    }

    const initialValues = {
        criteria: [
            // {}, /* initial values are empty as form is dynamic */
        ],
    }

    return (
        <Box>
            <Formik
                enableReinitialize={true}
                initialValues={
                    cohortBuilderFormData?.criteria?.length > 0 ? cohortBuilderFormData : initialValues
                }
                // validationSchema={validationSchema}
                onSubmit={onSubmitHandler}
                validateOnChange={false} //added to reduce renders, bottlenecks for field array
                validateOnBlur={false}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    isValid,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                    setTouched,
                }) => (
                    <Form noValidate autoComplete="off">
                        <FieldArray name="criteria">
                            {({ push, remove, insert }) => (
                                <Grid
                                    container
                                    columnSpacing={0}
                                    rowSpacing={12}
                                    wrap="wrap"
                                    gridDirection="column"
                                >
                                    {values?.criteria?.map((p, index) => {
                                        // const id = `criteria[${index}].id`
                                        // const criterionValues = `criteria[${index}].criterionValues`
                                        const criteriaOperator = `criteria[${index}].criteriaOperator`
                                        return (
                                            <>
                                                <Grid key={index} item xs={12} sm={6} md={4}>
                                                    <Box
                                                        sx={{
                                                            position: 'relative',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: variables.gray50,
                                                            color: variables.gray500,
                                                            textTransform: 'uppercase',
                                                            border: `1px solid ${variables.lightGray1}`,
                                                            p: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: variables.black200,
                                                                fontSize: '0.875rem',
                                                                fontWeight: '500 !important',
                                                            }}
                                                        >
                                                            Criterion #{index + 1}
                                                        </Typography>

                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => remove(index)}
                                                            sx={{ mx: 0.2 }}
                                                            /* if delete fucntionaly needs to be disabled for first criterion box */
                                                            // disabled={values?.criteria?.length === 1}
                                                        >
                                                            <DeleteIcon
                                                                sx={{
                                                                    color: variables.red600,
                                                                    cursor: 'pointer',
                                                                    /* if delete fucntionaly needs to be disabled for first criterion box */
                                                                    //     color:
                                                                    //         values?.criteria?.length === 1
                                                                    //             ? variables.gray200
                                                                    //             : variables.red600,
                                                                    //     cursor:
                                                                    //         values?.criteria?.length === 1
                                                                    //             ? 'not-allowed'
                                                                    //             : 'pointer',
                                                                }}
                                                            />
                                                        </IconButton>

                                                        {/* Outer OR/AND */}
                                                        <FormComponent
                                                            inputType="dropdown"
                                                            inputName={criteriaOperator ?? null}
                                                            inputVal={p?.criteriaOperator}
                                                            optionArr={cohortCriteriaOperators}
                                                            inputStyle="z-dropdown"
                                                            dropdownPositionFlag={true}
                                                        />
                                                    </Box>

                                                    <Box
                                                        style={{
                                                            padding: 16,
                                                            backgroundColor: variables.white100,
                                                            border: `1px solid ${variables.lightGray1}`,
                                                            borderTop: 'none',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <FieldArray
                                                            name={`criteria[${index}].criterionValues`}
                                                        >
                                                            {({ push, remove, insert }) => (
                                                                <>
                                                                    {values?.criteria[
                                                                        index
                                                                    ]?.criterionValues?.map(
                                                                        (criterion, idx) => {
                                                                            const category = `criteria[${index}].criterionValues[${idx}].category`
                                                                            const status = `criteria[${index}].criterionValues[${idx}].status`
                                                                            const marker = `criteria[${index}].criterionValues[${idx}].marker`
                                                                            const comparisonOperator = `criteria[${index}].criterionValues[${idx}].comparisonOperator`
                                                                            const value = `criteria[${index}].criterionValues[${idx}].value`
                                                                            const criterionOperator = `criteria[${index}].criterionValues[${idx}].criterionOperator`

                                                                            return (
                                                                                <>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: 'flex',
                                                                                            alignItems:
                                                                                                'center',

                                                                                            justifyContent:
                                                                                                'flex-start',
                                                                                            gap: 1,
                                                                                            p: 1,

                                                                                            flexWrap: 'wrap',
                                                                                            position:
                                                                                                'relative',
                                                                                        }}
                                                                                    >
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            onClick={() =>
                                                                                                remove(idx)
                                                                                            }
                                                                                            size="small"
                                                                                            sx={{
                                                                                                padding:
                                                                                                    '4px',
                                                                                                position:
                                                                                                    'absolute',
                                                                                                left: '-5%',
                                                                                                top: '0.7rem',
                                                                                            }}
                                                                                        >
                                                                                            <BackspaceIcon
                                                                                                sx={{
                                                                                                    color: variables.red600,
                                                                                                    cursor: 'pointer',
                                                                                                    fontSize:
                                                                                                        '16px',
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>

                                                                                        <FormComponent
                                                                                            inputType="dropdown"
                                                                                            inputName={
                                                                                                category
                                                                                            }
                                                                                            inputVal={
                                                                                                criterion.category
                                                                                            }
                                                                                            optionArr={
                                                                                                cohortCategories
                                                                                            }
                                                                                            inputStyle="dark-colored-dropdown"
                                                                                        />
                                                                                        {criterion?.category ===
                                                                                        'Medical History' ? (
                                                                                            <>
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        status
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion.status
                                                                                                    }
                                                                                                    optionArr={
                                                                                                        CohortLinkOperators
                                                                                                    }
                                                                                                    inputStyle="simple-dropdown"
                                                                                                />
                                                                                                <FormComponent
                                                                                                    inputType="label"
                                                                                                    labelValue="of"
                                                                                                />
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        marker
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion.marker
                                                                                                    }
                                                                                                    optionArr={categoryMapping(
                                                                                                        `${criterion?.category}`
                                                                                                    )}
                                                                                                    inputStyle="light-colored-dropdown"
                                                                                                />
                                                                                            </>
                                                                                        ) : criterion?.category ===
                                                                                          'Medication' ? (
                                                                                            <>
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        status
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion?.status
                                                                                                    }
                                                                                                    optionArr={
                                                                                                        CohortLinkOperators
                                                                                                    }
                                                                                                    inputStyle="simple-dropdown"
                                                                                                />
                                                                                                <FormComponent
                                                                                                    inputType="label"
                                                                                                    labelValue="of"
                                                                                                />
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        marker
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion?.marker
                                                                                                    }
                                                                                                    optionArr={categoryMapping(
                                                                                                        `${criterion?.category}`
                                                                                                    )}
                                                                                                    inputStyle="light-colored-dropdown"
                                                                                                />
                                                                                            </>
                                                                                        ) : criterion?.category ===
                                                                                          'Labs' ? (
                                                                                            <>
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        marker
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion?.marker
                                                                                                    }
                                                                                                    optionArr={categoryMapping(
                                                                                                        `${criterion?.category}`
                                                                                                    )}
                                                                                                    inputStyle="light-colored-dropdown"
                                                                                                />
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        justifyContent:
                                                                                                            'flex-start',

                                                                                                        columnGap: 1,
                                                                                                        p: 1,
                                                                                                        flexWrap:
                                                                                                            'wrap',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormComponent
                                                                                                        inputType="label"
                                                                                                        labelValue="Value"
                                                                                                    />
                                                                                                    <FormComponent
                                                                                                        inputType="dropdown"
                                                                                                        inputName={
                                                                                                            comparisonOperator
                                                                                                        }
                                                                                                        inputVal={
                                                                                                            criterion?.comparisonOperator
                                                                                                        }
                                                                                                        optionArr={
                                                                                                            cohortComparisonOperators
                                                                                                        }
                                                                                                        inputStyle="small-dropdown"
                                                                                                    />
                                                                                                    <FormComponent
                                                                                                        inputType="textInput"
                                                                                                        inputName={
                                                                                                            value
                                                                                                        }
                                                                                                        inputVal={
                                                                                                            criterion?.value
                                                                                                        }
                                                                                                        inputStyle="simple-textInput"
                                                                                                        placeholderText={
                                                                                                            'mg/dL'
                                                                                                        }
                                                                                                    />
                                                                                                </Box>
                                                                                            </>
                                                                                        ) : criterion?.category ===
                                                                                          'Demographics' ? (
                                                                                            <>
                                                                                                <FormComponent
                                                                                                    inputType="dropdown"
                                                                                                    inputName={
                                                                                                        marker
                                                                                                    }
                                                                                                    inputVal={
                                                                                                        criterion?.marker
                                                                                                    }
                                                                                                    optionArr={categoryMapping(
                                                                                                        `${criterion?.category}`
                                                                                                    )}
                                                                                                    inputStyle="light-colored-dropdown"
                                                                                                />
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        justifyContent:
                                                                                                            'flex-start',
                                                                                                        gap: 1,
                                                                                                        // padding: 1,
                                                                                                        padding:
                                                                                                            '0px 8px 0px 8px',

                                                                                                        flexWrap:
                                                                                                            'wrap',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormComponent
                                                                                                        inputType="label"
                                                                                                        labelValue="Value"
                                                                                                    />
                                                                                                    <FormComponent
                                                                                                        inputType="dropdown"
                                                                                                        inputName={
                                                                                                            comparisonOperator
                                                                                                        }
                                                                                                        inputVal={
                                                                                                            criterion?.comparisonOperator
                                                                                                        }
                                                                                                        optionArr={
                                                                                                            cohortComparisonOperators
                                                                                                        }
                                                                                                        inputStyle="small-dropdown"
                                                                                                    />
                                                                                                    <FormComponent
                                                                                                        inputType="textInput"
                                                                                                        inputName={
                                                                                                            value
                                                                                                        }
                                                                                                        inputVal={
                                                                                                            criterion?.value
                                                                                                        }
                                                                                                        inputStyle="simple-textInput"
                                                                                                        placeholderText={
                                                                                                            'years'
                                                                                                        }
                                                                                                    />
                                                                                                </Box>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </Box>
                                                                                    <Box
                                                                                        sx={{
                                                                                            p: 1,
                                                                                        }}
                                                                                    >
                                                                                        <FormComponent
                                                                                            inputType="dropdown"
                                                                                            inputName={
                                                                                                criterionOperator
                                                                                            }
                                                                                            inputVal={
                                                                                                criterion.criterionOperator
                                                                                            }
                                                                                            optionArr={
                                                                                                cohortCriteriaOperators
                                                                                            }
                                                                                            inputStyle="small-dropdown"
                                                                                        />
                                                                                    </Box>
                                                                                </>
                                                                            )
                                                                        }
                                                                    )}
                                                                    <IconButton
                                                                        edge="start"
                                                                        color="inherit"
                                                                        aria-label="Add sub-criterion"
                                                                        sx={{
                                                                            mx: 1,
                                                                            border: '1px dashed #D1D5DB',
                                                                            padding: '4px 8px',
                                                                            borderRadius: '11px',
                                                                            gap: 1,
                                                                        }}
                                                                        onClick={() => {
                                                                            push({
                                                                                category: null,
                                                                                status: null,
                                                                                marker: null,
                                                                                comparisonOperator: null,
                                                                                value: null,
                                                                                criterionOperator: null,
                                                                            })
                                                                        }}
                                                                    >
                                                                        <AddCircleIcon
                                                                            sx={{ color: variables.purple }}
                                                                        />{' '}
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent:
                                                    values?.criteria?.length > 0 ? 'space-evenly' : 'start',
                                                alignSelf: 'center',
                                                alignContent: 'flex-end',
                                                height: values?.criteria?.length > 0 ? '100%' : '8rem',
                                            }}
                                        >
                                            <IconButton
                                                color="inherit"
                                                aria-label="close"
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    border: '1px dashed #D1D5DB',
                                                    padding: '8px',
                                                    borderRadius: '11px',
                                                }}
                                                onClick={() => {
                                                    push({
                                                        // id: 1,
                                                        criteriaOperator: null,
                                                    })
                                                }}
                                            >
                                                <AddCircleIcon sx={{ color: variables.purple }} />
                                                <Typography className="fs-8 fw-bold">
                                                    Add Criterion
                                                </Typography>
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </FieldArray>

                        <Box className="d-flex justify-content-end" sx={{ pt: 8, pb: 4 }}>
                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                className="btn-secondary"
                                endIcon={<ArrowForwardIcon />}
                            >
                                Review Cohort Trial Criteria
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.accessToken,
    cohortBuilderFormData: state.cohortBuilder.cohortBuilderFormData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCohortBuilderFormData: (payload) => dispatch(setCohortBuilderFormData(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CohortForm)
