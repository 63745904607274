import {
    SET_COHORT_BUILDER_FORM_DATA,
    SET_COHORT_BUILDER_FORM_DATA_LOADING,
    CLEAR_COHORT_BUILDER_FORM_DATA,

    /* form response */
    SET_COHORT_FORM_RESPONSE,
    SET_COHORT_FORM_RESPONSE_LOADING,
    CLEAR_COHORT_FORM_RESPONSE,
} from './cohortBuilder.type'

export const setCohortBuilderFormData = (data) => {
    return {
        type: SET_COHORT_BUILDER_FORM_DATA,
        payload: data,
    }
}

export const setCohortBuilderFormDataLoading = (data) => {
    return {
        type: SET_COHORT_BUILDER_FORM_DATA_LOADING,
        payload: data,
    }
}

export const clearCohortBuilderFormData = () => {
    return {
        type: CLEAR_COHORT_BUILDER_FORM_DATA,
    }
}

export const setCohortFormResponse = (data) => {
    return {
        type: SET_COHORT_FORM_RESPONSE,
        payload: data,
    }
}

export const setCohortFormResponseLoading = (data) => {
    return {
        type: SET_COHORT_FORM_RESPONSE_LOADING,
        payload: data,
    }
}

export const clearCohortFormResponse = () => {
    return {
        type: CLEAR_COHORT_FORM_RESPONSE,
    }
}
