import * as React from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import variables from '../../theme/variables.scss'
import IconButton from '@mui/material/IconButton'
import LaunchIcon from '@mui/icons-material/Launch'
import Checkbox from '@mui/material/Checkbox'
import { DATE_FORMAT, DATE_AND_TIME_FORMAT } from '../../util/dateTimeFormats'
import Loading from '../Loading'
import _ from 'lodash'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: variables.gray50,
    color: variables.gray500,
    textTransform: 'capitalize',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function PatientListTable({
  tableHeaderData,
  loading,
  tableBodyData,
  togglePopUp,
  setTogglePopUp,
  checkboxFlag = false,
  setTogglePatientInfoPopUp,
  appointmentKeyFlag = false,
}) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        fontSize: '14px',
        borderRadius: '11px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        minHeight: 'calc(100vH - 250px)',
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHeaderData?.map((header, index) => {
              return (
                <StyledTableCell
                  //  align={header?.align ?? 'left'}
                  key={index}
                >
                  {header.name}
                </StyledTableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell sx={{ border: 'none !important' }} colSpan={4}>
                {/* <Loading /> */}
                <Box className="d-flex flex-column align-items-center justify-content-center">
                  <Loading />
                  <Typography className="gray-600 fw-normal fs-7 pb-2">Fetching Patient Data...</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {/* no data condiiton */}
              {!!(tableBodyData?.length === 0 || _.isEmpty(tableBodyData)) ? (
                <TableRow>
                  <TableCell sx={{ border: 'none !important' }} colSpan={4}>
                    <Typography
                      sx={{
                        color: variables.black200,

                        textAlign: 'center',
                        fontSize: '14px',
                        padding: '20px',
                      }}
                    >
                      No Data Available
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {tableBodyData?.map((row) => (
                    <StyledTableRow key={row.name}>
                      {!!checkboxFlag ? (
                        <StyledTableCell>
                          <Checkbox />
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell width={'290'} component="th" scope="row">
                        {row?.name}{' '}
                        {!!row?.showPopup ? (
                          <IconButton
                            aria-label="open-popup"
                            onClick={() => {
                              row?.popupFn()
                            }}
                          >
                            <LaunchIcon
                              sx={{
                                color: variables.purple,
                                cursor: 'pointer',
                                width: '12px',
                                height: '12px',
                              }}
                            />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{ color: variables.black200, fontWeight: '500' }}
                        // width={'200'}
                      >
                        {appointmentKeyFlag
                          ? !_.isEmpty(row?.date) && moment(row?.date).isValid()
                            ? moment(row?.date).format(DATE_FORMAT.MMM_DD_YYYY)
                            : 'N/A'
                          : !_.isEmpty(row?.dob) && moment(row?.dob).isValid()
                          ? moment(row?.dob).format(DATE_FORMAT.MMM_DD_YYYY)
                          : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell
                      //  width={'300'}
                      >
                        PH# {row?.phNo ?? 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {!_.isEmpty(row?.future_appt_date) && moment(row?.future_appt_date).isValid()
                          ? moment(row?.future_appt_date).format(DATE_FORMAT.MMM_DD_YYYY)
                          : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.patient_statuses.map((patient_status) => (
                          <div>
                            <b>{patient_status.status}</b> (
                            {moment(patient_status.updated_at).format(DATE_AND_TIME_FORMAT.default)})
                          </div>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
