import React from 'react'
import { connect, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Cath from './Cath'
import Ct from './Ct'
import Mri from './Mri'
import { getCathDocument } from '../../redux/CCM/cathList/cathList.action'
import { getCtDocument } from '../../redux/CCM/CTList/CTList.action'
import { getMriDocument } from '../../redux/CCM/MRIList/MRIList.action'
import { toggleCathModal, toggleCtModal, toggleMriModal } from '../../redux/modals/toggleModal.actions'
import { DATE_FORMAT } from '../../util/dateTimeFormats'

const AntTabs = styled(Tabs)({
  borderBottom: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: '#4F46E5',
  },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: 500,
  marginRight: theme.spacing(2),
  padding: 0,
  color: '#4B5563',
  fontFamily: 'Inter',
  '&:hover': {
    color: '#4F46E5',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#4F46E5',
    fontWeight: 600,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}))

export const Index = ({ cathLUD, patientId, token, ctLUD, mriLUD }) => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    getInitialValue(newValue)
  }

  React.useEffect(() => {
    const payload = {
      patientId: patientId,
    }

    if (patientId) {
      dispatch(getCathDocument(payload, token))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, token])

  const getInitialValue = (val) => {
    const sixtyMonth = moment().subtract(60, 'months').calendar()
    const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
    const payload = {
      patientId: patientId,
      date: proposedDate,
    }
    switch (val) {
      case 0:
        console.log('SWITCH CASE: ', payload)
        dispatch(getCathDocument(payload, token))
        break
      case 1:
        dispatch(getCtDocument(payload, token))
        break
      case 2:
        dispatch(getMriDocument(payload, token))
        break
      default:
        return null
    }
  }

  const GetLastUpdated = (val) => {
    switch (val) {
      case 0:
        return cathLUD !== ''
          ? `Refreshed ${moment.parseZone(cathLUD).format(DATE_FORMAT.MMM_DD_YYYY)}`
          : null
      case 1:
        return ctLUD !== '' ? `Refreshed ${moment.parseZone(ctLUD).format(DATE_FORMAT.MMM_DD_YYYY)}` : null
      case 2:
        return mriLUD !== '' ? `Refreshed ${moment.parseZone(mriLUD).format(DATE_FORMAT.MMM_DD_YYYY)}` : null
      default:
        return null
    }
  }

  const OpenModal = (val) => {
    const sixtyMonth = moment().subtract(60, 'months').calendar()
    const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
    const payload = {
      patientId: patientId,
      date: proposedDate,
    }
    switch (val) {
      case 0:
        console.log('SWITCH CASE OPEN MODAL: ', payload)
        dispatch(getCathDocument(payload, token))
        dispatch(toggleCathModal(true))
        break
      case 1:
        dispatch(getCtDocument(payload, token))
        dispatch(toggleCtModal(true))
        break
      case 2:
        dispatch(getMriDocument(payload, token))
        dispatch(toggleMriModal(true))
        break
      default:
    }
  }

  return (
    <div>
      <Accordion
        className="dashboard-accordion"
        sx={{ boxShadow: 'none' }}
        expanded={true}
        // onChange={handleChange('panel1')}
      >
        <AccordionSummary
          sx={{ background: '#F3F4F6', boxShadow: 'none', borderRadius: '0.25rem' }}
          // expandIcon={<ExpandMoreIcon  />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            className="gray-600 cursor-default"
            sx={{
              width: '100%',
              color: 'text.secondary',
              textAlign: 'right',
              fontSize: '0.85rem',
            }}
          >
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label="Cath" />
              <AntTab label="CT" />
              <AntTab label="MRI" />
            </AntTabs>
          </Typography>

          <Typography
            className="gray-600 cursor-default"
            sx={{
              width: '100%',
              color: 'text.secondary',
              textAlign: 'right',
              fontSize: '0.85rem',
              lineHeight: 3.5,
            }}
          >
            {GetLastUpdated(value)}
            <span className="ms-2 me-1 cursor-pointer">
              <img onClick={() => OpenModal(value)} src="/images/right-top-arrow.svg" alt="go to icon" />
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {value === 0 && (
            <>
              <Cath />
            </>
          )}
          {value === 1 && (
            <>
              <Ct />
            </>
          )}
          {value === 2 && (
            <>
              <Mri />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const mapStateToProps = (state) => ({
  patientId: state.patient.patientData.patientId,
  token: state.loginData.loginResponse.accessToken,
  cathLUD: state.cath.lud,
  cathDocuments: state.cath.cathDocuments,
  ctLUD: state.CT.lud,
  ctDocuments: state.CT.CTDocuments,
  mriLUD: state.MRI.lud,
  mriDocuments: state.MRI.mriDocuments,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getCathDocument: (payload, token) => dispatch(getCathDocument(payload, token)),
    getCtDocument: (payload, token) => dispatch(getCtDocument(payload, token)),
    getMriDocument: (payload, token) => dispatch(getMriDocument(payload, token)),
    toggleCathModal: (payload) => dispatch(toggleCathModal(payload)),
    toggleCtModal: (payload) => dispatch(toggleCtModal(payload)),
    toggleMriModal: (payload) => dispatch(toggleMriModal(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
