import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import variables from '../../../../../theme/variables.scss'

const FormComponent = ({
    inputType,
    inputName,
    inputVal,
    optionArr,
    inputStyle,
    labelValue,
    placeholderText = null,
    dropdownPositionFlag = false,
    dropdownIconFlag = false,
    visbilityFlag = false,
}) => {
    const { setFieldValue, handleChange, handleBlur } = useFormikContext()

    const sytleHandler = (type) => {
        switch (type) {
            case 'z-dropdown':
                return {
                    width: 'fit-content',
                    height: '30px',
                    fontSize: '12px',
                    color: variables.gray900,
                    backgroundColor: variables.white100,
                    // border: `1px solid ${variables.lightGray1}`,
                    padding: '2px 6px 2px !important',
                    borderColor: variables.gray200,
                    borderRadius: '80px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                }

            case 'z-postion-flag':
                return { position: 'absolute', left: '93%', top: '20%', zIndex: 999 }

            case 'dark-colored-dropdown':
                return {
                    width: 'fit-content',
                    height: '30px',
                    // fontSize: '12px',
                    color: variables.white100,
                    backgroundColor: variables.purple,
                    border: `1px solid ${variables.lightGray1}`,
                    fontWeight: '400 !important',
                    fontSize: '0.75rem',
                    '& .MuiSvgIcon-root': {
                        color: variables.white100,
                    },
                    //padding: '2px 6px 2px !important',// reducing this comment
                    // borderColor: variables.gray200,
                    borderRadius: '80px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                }

            case 'simple-dropdown':
                return {
                    width: 'fit-content',
                    height: '30px',
                    fontSize: '12px',

                    //padding: '2px 6px 2px !important', // reducing this comment
                    borderColor: variables.gray200,
                    borderRadius: '80px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                }

            case 'light-colored-dropdown':
                return {
                    width: 'fit-content',
                    height: '30px',
                    // padding: '2px 6px 2px !important', //
                    borderColor: variables.gray200,

                    minWidth: '3.5rem !important',
                    color: variables.gray900,
                    backgroundColor: variables.lightPurple,
                    // border: `1px solid ${variables.lightGray1}`,
                    //borderRadius: '80px', // for round dropdown
                    borderRadius: '5px', // for squarish dropdown
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                }
            case 'small-dropdown':
                return {
                    width: 'fit-content',
                    height: '30px',
                    fontSize: '12px',
                    //padding: '2px 6px 2px !important', //
                    borderColor: variables.gray200,
                    borderRadius: '80px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.lightGray1,
                            outline: 'none',
                        },
                    },
                }
            case 'simple-textInput':
                return {
                    width: '5rem !important',
                    // borderRadius: '80px !important',
                    borderColor: variables.lightGray1,
                    '& .MuiOutlinedInput-root': {
                        // borderRadius: '80px !important',
                        '& fieldset': {
                            borderColor: variables.lightGray1,
                            // borderRadius: '80px !important',
                            fontSize: '14px',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: variables.purple,
                            borderWidth: '1px',
                            // borderRadius: '80px !important',
                        },
                        '&:hover fieldset': {
                            borderColor: variables.purple,
                            borderWidth: '1px',
                            // borderRadius: '80px !important',
                        },
                        '&:focus fieldset': {
                            borderColor: variables.purple,
                            // borderRadius: '80px !important',
                        },
                    },
                    '&:focus': {
                        '&& fieldset': {
                            borderColor: variables.purple,
                            borderWidth: '1px',
                            outline: 'none !important',
                        },
                    },
                }
            default:
                return {}
        }
    }

    switch (inputType) {
        case 'heading':
            return (
                <Typography
                    sx={{
                        color: variables.black200,
                        fontSize: '0.875rem',
                        fontWeight: '500 !important',
                    }}
                    id={inputName}
                    name={inputName}
                    value={inputVal}
                    onChange={handleChange}
                >
                    Criterion #1
                </Typography>
            )
        case 'dropdown':
            return (
                <FormControl size="small" sx={!!dropdownPositionFlag ? sytleHandler('z-postion-flag') : {}}>
                    <Select
                        onClose={() => {
                            setTimeout(() => {
                                document.activeElement.blur()
                            }, 0)
                        }}
                        value={inputVal}
                        name={inputName}
                        onChange={handleChange}
                        sx={sytleHandler(inputStyle)}
                        displayEmpty
                    >
                        <MenuItem value={null} disabled>
                            Select
                        </MenuItem>
                        {optionArr?.map((btn, idx) => (
                            <MenuItem
                                key={idx}
                                name={btn?.name}
                                value={btn?.id}
                                aria-label={btn?.name}
                                sx={{
                                    textTransform: 'capitalize !important',
                                }}
                            >
                                {!!dropdownIconFlag ? <AddIcon fontSize="small" /> : null}{' '}
                                {btn?.name ?? 'select'}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        case 'label':
            return (
                <Typography className="fs-8" component={'span'}>
                    {labelValue}
                </Typography>
            )
        case 'small-add-button':
            return (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Add sub-criterion"
                    sx={{
                        mx: 1,
                    }}
                    // onClick={() => {}}
                >
                    <AddCircleIcon sx={{ color: variables.purple }} />
                </IconButton>
            )
        case 'large-add-button':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-evenly',
                        alignSelf: 'center',
                        alignContent: 'flex-end',
                        height: '100%',
                    }}
                >
                    <IconButton
                        // edge="start"
                        color="inherit"
                        aria-label="close"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px dashed #D1D5DB',
                            padding: '8px',
                            borderRadius: '11px',
                        }}
                        // onClick={() => {}}
                    >
                        <AddCircleIcon sx={{ color: variables.purple }} />
                        <Typography className="fs-8 fw-bold">Add Criterion</Typography>
                    </IconButton>
                </Box>
            )
        case 'textInput':
            return (
                <>
                    <TextField
                        className="m-1"
                        id={inputName}
                        name={inputName}
                        value={inputVal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholderText}
                        InputProps={{
                            style: {
                                fontSize: '12px',
                                height: '30px',
                                borderColor: variables.lightGray1,
                            },
                        }}
                        sx={{
                            ...sytleHandler(inputStyle),
                            width: '4rem !important',
                            display: !!visbilityFlag ? 'none' : 'initial',
                        }}
                    />
                </>
            )

        default:
            return <p> Invalid Input </p>
    }
}

export default FormComponent
