import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import '../../components/interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../theme/variables.scss'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { trialList } from '../../util/screen-flow-data'
import CriteriaDetails from '../screen-flow/screen-flow-modal/internal-components/CriteriaDetails'
import { togglePatientInfoModal } from '../../redux/modals/toggleModal.actions'
import { DATE_FORMAT } from '../../util/dateTimeFormats'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minHeight: '500px',
    minWidth: '500px',
    display: 'flex',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    background: variables.gray100,
    fontWeight: 600,
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          variant="contained"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export const PatientInfoModal = ({
  togglePatientInfoPopUp,
  setTogglePatientInfoPopUp,
  patientInfoModal,
  patientInfoData,
}) => {
  const trialListHandler = () => {
    switch ('Zeus') {
      case 'Zeus':
        return trialList[0]
      case 'Hermes':
        return trialList[1]
      case 'Oceanic':
        return trialList[2]

      default:
        return {}
    }
  }

  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    dispatch(togglePatientInfoModal(false))
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        maxWidth="lg"
        fullWidth={true}
        open={!!patientInfoModal}
        PaperProps={{
          style: {
            minHeight: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography className="fs-7 gray-600 fw-light">Patient Information</Typography>
          <Typography className="fs-6 fw-bold text-uppercase">
            {patientInfoData?.title ?? 'Criteria'}
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
          <Box sx={{ px: 2, pb: 2, width: '100%' }}>
            <Box className="pb-4 mt-4 px-2">
              <Stack direction="row" spacing={4}>
                <Box>
                  <Typography className="gray-600 fs-7 fw-normal">Patient Name</Typography>
                  <Typography className="gray-900 fs-8 fw-bold">{patientInfoData?.name ?? 'N/A'}</Typography>
                </Box>
                <Box>
                  <Typography className="fs-8 fw-normal gray-600">D.O.B: </Typography>
                  <Typography className="fs-8 fw-normal gray-900">
                    {!_.isEmpty(patientInfoData?.date_of_birth) &&
                    moment(patientInfoData?.date_of_birth).isValid()
                      ? moment(patientInfoData?.date_of_birth).format(DATE_FORMAT.MMM_DD_YYYY)
                      : 'N/A'}
                  </Typography>
                </Box>
                <Box direction="row" spacing={1}>
                  <Typography className="fs-8 fw-normal gray-600">Age: </Typography>
                  <Typography className="fs-8 fw-normal gray-900">
                    {' '}
                    {patientInfoData?.age ?? 'N/A'} yr{' '}
                  </Typography>
                </Box>
                <Box direction="row" spacing={1}>
                  <Typography className="fs-8 fw-normal gray-600">Gender: </Typography>
                  <Typography className="fs-8 fw-normal gray-900 ">
                    {patientInfoData?.gender ?? 'N/A'}
                  </Typography>
                </Box>

                <Box>
                  <Typography className="fs-8 fw-normal gray-600">Upcoming appointment</Typography>
                  <Typography className="fs-8 fw-normal gray-900 ">
                    {!_.isEmpty(patientInfoData?.future_appt_date) &&
                    moment(patientInfoData?.future_appt_date).isValid()
                      ? moment(patientInfoData?.future_appt_date).format(DATE_FORMAT.MMM_DD_YYYY)
                      : 'N/A'}
                  </Typography>
                </Box>
              </Stack>

              <Box sx={{ py: 2 }}>
                <CriteriaDetails
                  trialData={trialListHandler()}
                  trialListLoading={false}
                  trialCriteriaLoading={false}
                  trialCriteriaData={patientInfoData?.criterion}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    patientInfoModal: state?.toggleModals?.patientInfoModal,
    patientInfoData: state?.screenFlowPortalClinicalTrial?.patientInfoData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    togglePatientInfoModal: (payload) => dispatch(togglePatientInfoModal(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoModal)
