import {
  /* clinic list */
  GET_CLINIC_LIST_DATA,
  SET_CLINIC_LIST_LOADING,

  /* clinical trial list */
  GET_CLINICAL_TRIAL_LIST_DATA,
  SET_CLINICAL_TRIAL_LIST_LOADING,
  GET_CLINICAL_TRIAL_CRITERIA_DATA,
  SET_CLINICAL_TRIAL_CRITERIA_LOADING,
  CLEAR_CLINICAL_TRIAL_CRITERIA,
  // GET_CLINICAL_TRIAL_DATA, //new
  // SET_CLINICAL_TRIAL_LOADING, //new
  SET_CURRENT_CLINICAL_TRIAL,
  SET_ELIGIBILITY_CRITERIA,
  CLEAR_CLINICAL_TRIAL,

  /* matched & patially matched */
  GET_MATCHED_PATIENT_LIST,
  GET_PARTIALLY_MATCHED_PATIENT_LIST,
  SET_MATCHED_PATIENT_LIST_LOADING,
  SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING,
  CLEAR_MATCHED_PATIENT,
  CLEAR_PARTIALLY_MATCHED_PATIENT,

  /* Gold Patient list */
  GET_GOLD_PATIENT_LIST,
  SET_GOLD_PATIENT_LIST_LOADING,
  CLEAR_GOLD_PATIENT,

  /* Gold matches pdf */
  GET_GOLD_MATCHES_PDF,
  SET_GOLD_MATCHES_PDF_LOADING,
  CLEAR_GOLD_MATCHES_PDF,

  /* Silver matches pdf */
  GET_SILVER_MATCHES_PDF,
  SET_SILVER_MATCHES_PDF_LOADING,
  CLEAR_SILVER_MATCHES_PDF,

  /* Silver Patient list */
  GET_SILVER_PATIENT_LIST,
  SET_SILVER_PATIENT_LIST_LOADING,
  CLEAR_SILVER_PATIENT,

  /* Gold Patient summary */
  GET_GOLD_PATIENT_SUMMARY,
  SET_GOLD_PATIENT_SUMMARY_LOADING,
  CLEAR_GOLD_PATIENT_SUMMARY,
  /* Silver patient summary */
  GET_SILVER_PATIENT_SUMMARY,
  SET_SILVER_PATIENT_SUMMARY_LOADING,
  CLEAR_SILVER_PATIENT_SUMMARY,

  /* provider list */
  GET_PROVIDER_LIST,
  SET_PROVIDER_LIST_LOADING,
  SET_CURRENT_PROVIDERS,

  /* Upcoming patient appointment list */
  SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,
  CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,

  /* patient info data */
  SET_PATIENT_INFO_DATA,
  CLEAR_PATIENT_INFO_DATA,

  /* matched pdf download */
  GET_MATCHES_PDF,
  SET_MATCHES_PDF_LOADING,
  CLEAR_MATCHES_PDF,

  /* partial matched pdf download */
  SET_PARTIAL_MATCHES_PDF_LOADING,
  GET_PARTIAL_MATCHES_PDF,
  CLEAR_PARTIAL_MATCHES_PDF,

  /* matched appointment pdf download */
  GET_APPOINTMENT_PDF,
  SET_APPOINTMENT_PDF_LOADING,
  CLEAR_APPOINTMENT_PDF,

  /* matched and parial match trial count */
  GET_MATCHED_PATIENT_TRIAL_COUNT,
  SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  CLEAR_MATCHED_PATIENT_TRIAL_COUNT,
  GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
  SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
} from './screenFlow.type'
import axios from 'axios'
import { toggleDeactivateModal, toggleSessionModal } from '../../modals/toggleModal.actions'
import { setAlert } from '../../alert/alert.action'
import { STATUS_CODE } from '../../../util'
import { commonErrorHandler, networkErrorHandler } from '../../../util'
import _ from 'lodash'

export const setCurrentTrial = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_CLINICAL_TRIAL,
    payload: data,
  })
}
export const setEligibilityCriteria = (data) => (dispatch) => {
  dispatch({
    type: SET_ELIGIBILITY_CRITERIA,
    payload: data,
  })
}

export const clearTrial = () => (dispatch) => {
  dispatch({
    type: CLEAR_CLINICAL_TRIAL,
  })
}

export const getTrialListData = (payload, token) => async (dispatch) => {
  dispatch({
    type: SET_CLINICAL_TRIAL_LIST_LOADING,
    payload: true,
  })
  axios
    .post(`${process.env.REACT_APP_CARD_URL}/clinical-trials`, payload, {
      headers: {
        accessToken: token,
      },
    })
    .then((response) => {
      if (response.data.status === 200) {
        dispatch({
          type: GET_CLINICAL_TRIAL_LIST_DATA,
          payload: response.data.output,
        })
      } else if (response.data.status === 400) {
        dispatch(
          setAlert({
            alertType: 'error',
            alertMsg: `Error in fetching Clinical Trial List. Please! try Again`,
            alertOpen: true,
          })
        )
      } else if (response.data.status === 401) {
        dispatch(toggleSessionModal(true, response?.data?.error))
      } else if (response.data.status === 403) {
        dispatch(toggleDeactivateModal(true))
      }
    })
    .catch((err) => {
      dispatch(
        setAlert({
          alertType: 'error',
          alertMsg: `${err?.message ?? 'Error'} in fetching clinical trials. Please! try Again`,
          alertOpen: true,
        })
      )
    })
    .finally(() => {
      dispatch({
        type: SET_CLINICAL_TRIAL_LIST_LOADING,
        payload: false,
      })
    })
}

const BASE_URL = process.env.REACT_APP_CARD_URL
const PATIENT_LIST_ENDPOINT = '/eligible-patients'
const PROVIDER_LIST_ENDPOINT = '/providers'
const PATIENT_LIST_PDF_ENDPOINT = '/downloadEligiblePatientsList'
const PATIENT_APPOINTMENT_LIST_PDF_ENDPOINT = '/downloadAppointmentsList'
const PATIENT_TRIAL_COUNT_ENDPOINT = '/eligible-patients-summary'

function constructUrl(endpoint, queryStr) {
  if (!_.isEmpty(queryStr)) {
    const removeUndefined = JSON.parse(JSON.stringify(queryStr))
    const result = new URLSearchParams(removeUndefined).toString()
    return result ? `${BASE_URL}${endpoint}?${result}` : `${BASE_URL}${endpoint}`
  }
  return `${BASE_URL}${endpoint}`
}

/* common action handler */
const getListData =
  (setLoadingType, getListType, urlEndpoint, titlePlaceholder) => (payload, token) => async (dispatch) => {
    dispatch({ type: setLoadingType, payload: true })
    try {
      const response = await axios.get(constructUrl(urlEndpoint, payload), {
        headers: { accessToken: token },
      })
      const { status, output, error } = response.data
      if (status === STATUS_CODE.SUCCESS) {
        dispatch({ type: getListType, payload: output })
      } else {
        commonErrorHandler(dispatch, status, error, titlePlaceholder)
      }
    } catch (err) {
      networkErrorHandler(dispatch, err)
    } finally {
      dispatch({ type: setLoadingType, payload: false })
    }
  }

export const getMatchedPatientList = getListData(
  SET_MATCHED_PATIENT_LIST_LOADING,
  GET_MATCHED_PATIENT_LIST,
  PATIENT_LIST_ENDPOINT,
  'matched patient list'
)
export const getPartiallyMatchedPatientList = getListData(
  SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING,
  GET_PARTIALLY_MATCHED_PATIENT_LIST,
  PATIENT_LIST_ENDPOINT,
  'partially matched patient list'
)

export const clearMatchedPatient = () => (dispatch) => {
  dispatch({
    type: CLEAR_MATCHED_PATIENT,
  })
}

export const clearPartiallyMatchedPatient = () => (dispatch) => {
  dispatch({
    type: CLEAR_PARTIALLY_MATCHED_PATIENT,
  })
}

/* provider list */
export const getProviderList = getListData(
  SET_PROVIDER_LIST_LOADING,
  GET_PROVIDER_LIST,
  PROVIDER_LIST_ENDPOINT,
  'provider list'
)

export const setCurrentProviders = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PROVIDERS,
    payload: data,
  })
}

/*  upcomming patient appointment */
export const setPatientAppointment = (data) => (dispatch) => {
  dispatch({
    type: SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,
    payload: data,
  })
}
export const clearPatientAppointment = () => (dispatch) => {
  dispatch({
    type: CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,
  })
}

/* patient info data */
export const setPatientInfoData = (data) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_INFO_DATA,
    payload: data,
  })
}
export const clearPatientInfoData = () => (dispatch) => {
  dispatch({
    type: CLEAR_PATIENT_INFO_DATA,
  })
}

/*  download matches pdf url */
export const getMatchedPatientPdf = getListData(
  SET_MATCHES_PDF_LOADING,
  GET_MATCHES_PDF,
  PATIENT_LIST_PDF_ENDPOINT,
  'matched patient list pdf'
)

export const clearMatchesPdf = () => (dispatch) => {
  dispatch({
    type: CLEAR_MATCHES_PDF,
  })
}

/*  download partial matches pdf */
export const getPartialMatchedPatientPdf = getListData(
  SET_PARTIAL_MATCHES_PDF_LOADING,
  GET_PARTIAL_MATCHES_PDF,
  PATIENT_LIST_PDF_ENDPOINT,
  'partially matched patient list pdf'
)

export const clearPartialMatchesPdf = () => (dispatch) => {
  dispatch({
    type: CLEAR_PARTIAL_MATCHES_PDF,
  })
}

/*  downlaod upcoming appointment pdf */
export const getAppointmentPdf = getListData(
  SET_APPOINTMENT_PDF_LOADING,
  GET_APPOINTMENT_PDF,
  PATIENT_APPOINTMENT_LIST_PDF_ENDPOINT,
  'patient appointment list pdf'
)

export const clearAppointmentPdf = () => (dispatch) => {
  dispatch({
    type: CLEAR_APPOINTMENT_PDF,
  })
}

/* matched and parial match trial count */
export const getMatchedPatientTrialCount = getListData(
  SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  GET_MATCHED_PATIENT_TRIAL_COUNT,
  PATIENT_TRIAL_COUNT_ENDPOINT,
  'matched patient trial summary'
)

export const clearMatchedPatientTrialCount = () => (dispatch) => {
  dispatch({
    type: CLEAR_MATCHED_PATIENT_TRIAL_COUNT,
  })
}

export const getPartiallyMatchedPatientTrialCount = getListData(
  SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
  PATIENT_TRIAL_COUNT_ENDPOINT,
  'matched patient trial summary'
)

export const clearPartiallyMatchedPatientTrialCount = () => (dispatch) => {
  dispatch({
    type: CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
  })
}

/* api gateway */
const SCREENFLOW_BASE_URL = process.env.REACT_APP_SCREENFLOW_BASE_URL
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
const CLINIC_LIST_ENDPOINT = '/clinics'
const CLINICAL_TRIAL_LIST_ENDPOINT = '/clinicaltrials'
const GOLD_LIST_ENDPOINT = '/goldlist'
const SILVER_LIST_ENDPOINT = '/silverlist'
const GOLD_LIST_SUMMARY_ENDPOINT = '/goldlist/summary'
const SILVER_LIST_SUMMARY_ENDPOINT = '/silverlist/summary'

function constructUrl_v1(endpoint, queryStr) {
  if (!_.isEmpty(queryStr)) {
    const removeUndefined = JSON.parse(JSON.stringify(queryStr))
    const result = new URLSearchParams(removeUndefined).toString()

    return result
      ? `${SCREENFLOW_BASE_URL}/${ENVIRONMENT}/api${endpoint}?${result}`
      : `${SCREENFLOW_BASE_URL}/${ENVIRONMENT}/api${endpoint}`
  }

  return `${SCREENFLOW_BASE_URL}/${ENVIRONMENT}/api${endpoint}`
}

/*  to get list of clinics */
export const getClinicListData_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_CLINIC_LIST_LOADING, payload: true })
  try {
    const response = await axios.get(constructUrl_v1(CLINIC_LIST_ENDPOINT, payload), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_CLINIC_LIST_DATA, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinic List')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_CLINIC_LIST_LOADING, payload: false })
  }
}

/*  to get list of clinicaltrials */
export const getTrialListData_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_CLINICAL_TRIAL_LIST_LOADING, payload: true })
  try {
    const response = await axios.get(constructUrl_v1(CLINICAL_TRIAL_LIST_ENDPOINT, payload), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_CLINICAL_TRIAL_LIST_DATA, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial List')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_CLINICAL_TRIAL_LIST_LOADING, payload: false })
  }
}

/*  to get particular trial data clinical/{id} or trial criteria */
export const getTrialCriteriaData_v1 = (id, clinic_id, token) => async (dispatch) => {
  dispatch({ type: SET_CLINICAL_TRIAL_CRITERIA_LOADING, payload: true })
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}?clinic_id=${clinic_id}`),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_CLINICAL_TRIAL_CRITERIA_DATA, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Data')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_CLINICAL_TRIAL_CRITERIA_LOADING, payload: false })
  }
}

/* clear trial criteria */
export const clearTrialCriteria = () => (dispatch) => {
  dispatch({ type: CLEAR_CLINICAL_TRIAL_CRITERIA })
}

/* gold list patients  */
export const getTrialGoldListData_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_GOLD_PATIENT_LIST_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${GOLD_LIST_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_GOLD_PATIENT_LIST, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Gold List')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_GOLD_PATIENT_LIST_LOADING, payload: false })
  }
}
/* clear gold patient */
export const clearGoldPatient = () => (dispatch) => {
  dispatch({ type: CLEAR_GOLD_PATIENT })
}

/* silver list patients */
export const getTrialSilverListData_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_SILVER_PATIENT_LIST_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${SILVER_LIST_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_SILVER_PATIENT_LIST, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Silver List')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_SILVER_PATIENT_LIST_LOADING, payload: false })
  }
}

/* clear silver list */
export const clearSilverPatient = () => (dispatch) => {
  dispatch({ type: CLEAR_SILVER_PATIENT })
}

/* gold list summary */
export const getGoldListSummary_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_GOLD_PATIENT_SUMMARY_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${GOLD_LIST_SUMMARY_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_GOLD_PATIENT_SUMMARY, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Gold List Summary')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_GOLD_PATIENT_SUMMARY_LOADING, payload: false })
  }
}

/* clear gold list summary */
export const clearGoldPatientSummary = () => (dispatch) => {
  dispatch({ type: CLEAR_GOLD_PATIENT_SUMMARY })
}

/* silver list summary */
export const getSilverListSummary_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_SILVER_PATIENT_SUMMARY_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${SILVER_LIST_SUMMARY_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_SILVER_PATIENT_SUMMARY, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Silver List Summary')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial List')
    }
  } finally {
    dispatch({ type: SET_SILVER_PATIENT_SUMMARY_LOADING, payload: false })
  }
}

/* clear silver list summary */
export const clearSilverPatientSummary = () => (dispatch) => {
  dispatch({ type: CLEAR_SILVER_PATIENT_SUMMARY })
}

/* get gold list pdf */
export const getTrialGoldListPdf_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_GOLD_MATCHES_PDF_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${GOLD_LIST_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_GOLD_MATCHES_PDF, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Gold List PDF')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Clinical Trial Gold List PDF')
    }
  } finally {
    dispatch({ type: SET_GOLD_MATCHES_PDF_LOADING, payload: false })
  }
}

/* clear gold list pdf */
export const clearGoldListPdf = () => (dispatch) => {
  dispatch({ type: CLEAR_GOLD_MATCHES_PDF })
}

/* get silver list pdf */
export const getTrialSilverListPdf_v1 = (payload, token) => async (dispatch) => {
  dispatch({ type: SET_SILVER_MATCHES_PDF_LOADING, payload: true })
  const { trial_id: id, ...requestBody } = payload
  try {
    const response = await axios.get(
      constructUrl_v1(`${CLINICAL_TRIAL_LIST_ENDPOINT}/${id}${SILVER_LIST_ENDPOINT}`, requestBody),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { status, data, error } = response
    if (status === STATUS_CODE.SUCCESS) {
      dispatch({ type: GET_SILVER_MATCHES_PDF, payload: data })
    } else {
      commonErrorHandler(dispatch, status, error, 'Clinical Trial Silver List PDF')
    }
  } catch (err) {
    if (!err.response) {
      // Network error
      networkErrorHandler(dispatch, err)
    } else {
      // HTTP error
      commonErrorHandler(
        dispatch,
        err?.response?.status,
        err?.response?.data,
        'Clinical Trial Silver List PDF'
      )
    }
  } finally {
    dispatch({ type: SET_SILVER_MATCHES_PDF_LOADING, payload: false })
  }
}

/* clear silver list pdf */
export const clearSilverListPdf = () => (dispatch) => {
  dispatch({ type: CLEAR_SILVER_MATCHES_PDF })
}
