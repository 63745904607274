import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../redux/alert/alert.action'
import { login } from '../../../redux/auth/auth.action'
import { Auth } from 'aws-amplify'

const STATUS_CODES = {
    OK: 200,
    BAD_REQUEST: 400,
}

export const Index = ({ isLoggedIn, loginData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const showAlert = (alertType, alertMsg) => {
        dispatch(
            setAlert({
                alertType,
                alertMsg,
                alertOpen: true,
            })
        )
    }

    useEffect(() => {
        const doLogin = async () => {
            try {
                const res = await Auth.currentSession()
                const token = res.getAccessToken()
                const jwtToken = token.getJwtToken()
                // Retrieve the current authenticated user
                const user = await Auth.currentAuthenticatedUser()
                const idToken = user.signInUserSession.getIdToken().getJwtToken()

                const headers = {
                    accessToken: jwtToken,
                    Authorization: `Bearer ${idToken}`,
                }
                const response = await axios.post(
                    `${process.env.REACT_APP_SCREENFLOW_BASE_URL}/${process.env.REACT_APP_ENVIRONMENT}/api/login`,
                    {},
                    { headers }
                )
                if (response?.status === STATUS_CODES.OK) {
                    dispatch(login(response?.data))
                } else if (response.data.status >= STATUS_CODES.BAD_REQUEST) {
                    showAlert('error', response?.data?.message)
                } else {
                    showAlert('error', 'Something went wrong! Please try again.')
                }
            } catch (err) {
                showAlert('error', 'Something went wrong! Please try again.')
                console.error(err)
            }
        }

        if (isLoggedIn) {
            navigate('/portals')
        } else {
            doLogin().catch(console.error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData, isLoggedIn])

    return <></>
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.loginData.isLoggedIn,
        loginData: state.loginData.loginResponse,
        resetPasswordModal: state.toggleModals.resetPasswordModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(login(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
