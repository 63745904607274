import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppBar from '../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../components/portal-components/stepper-tabs/StepperTabs'
import Tooltip from '@mui/material/Tooltip'
import TablePagination from '@mui/material/TablePagination'
import { TablePaginationActions } from '../components/appointment-list/index'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import SearchIcon from '@mui/icons-material/Search'
import variables from './../theme/variables.scss'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import TrialCriteriaSummary from '../components/portal-components/TrialCriteriaSummary'
import { Divider, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import '../components/social-history/social-form/social-form.scss'
import PatientListTable from '../components/portal-components/PatientListTable'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import HomeIcon from '@mui/icons-material/Home'
import Loading from '../components/Loading'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import ClearIcon from '@mui/icons-material/Clear'
import {
  getPartiallyMatchedPatientList,
  // setPatientAppointment,
  setPatientInfoData,
  clearMatchedPatient,
  clearPartiallyMatchedPatient,
  clearPatientAppointment,
  clearPatientInfoData,
  clearAppointmentPdf,
  clearMatchedPatientTrialCount,
  clearPartiallyMatchedPatientTrialCount,
  getTrialSilverListData_v1,
  clearSilverPatient,
  getSilverListSummary_v1,
  clearSilverPatientSummary,
  getTrialSilverListPdf_v1,
  clearGoldListPdf,
  clearSilverListPdf,
} from '../redux/searchFlow/screenFlow/screenFlow.action'
import { useQuery } from '../util'
import { toggleAppointmentModal, togglePatientInfoModal } from '../redux/modals/toggleModal.actions'
import { setAlert } from '../redux/alert/alert.action'
import _ from 'lodash'

export const ScreenFlow = ({
  login,
  token,
  partiallyMatchedPatientList,
  silverPatientList,
  silverPatientListLoading,
  silverPatientSummary,
  silverPatientSummaryLoading,
  silverMatchesPdf,
  silverMatchesPdfLoading,
}) => {
  const navigate = useNavigate()
  let query = useQuery()
  const dispatch = useDispatch()
  useEffect(() => {
    if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
      navigate('/')
    }
  })

  /* pagination states */
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  /* Search bar values */
  const [patientSearch, setPatientSearch] = React.useState('')

  const handlePartialMatchesPatientSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0)
      setPatientSearch(e.target.value)
      setInputSearch(e.target.value)

      const silverPayloadData = {
        clinic_id: query.get('clinic_id'),
        trial_id: query.get('trial_id'),
        search: e.target.value,
        page: 1,
        pageLimit: 10,
      }
      dispatch(getTrialSilverListData_v1(silverPayloadData, token))
    }
    if (e.target.value === '') {
      if (e.key === 'Backspace') {
        setPage(0)
        setPatientSearch(e.target.value)
        setInputSearch(e.target.value)

        const silverPayloadData = {
          clinic_id: query.get('clinic_id'),
          trial_id: query.get('trial_id'),
          search: '',
          page: 1,
          pageLimit: 10,
        }
        dispatch(getTrialSilverListData_v1(silverPayloadData, token))
      }
    }
  }

  /* pagination handler */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    // const payloadData = {
    //     collectionName: query.get('trial_id'),
    //     page: newPage + 1,
    //     pageLimit: 10,
    //     patientName: !!patientSearch ? patientSearch : inputSearch,
    //     partialMatch: true,
    // }
    /* WIP query api endpoint needed */
    // dispatch(getPartiallyMatchedPatientList(payloadData, token))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // on page render scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [inputSearch, setInputSearch] = React.useState('')

  const [downloadPdfFlag, setDownloadPdfFlag] = React.useState(false)

  const [stepValue, setStepValue] = React.useState('three')

  //* Initial fetch of partially matched patient list */
  useEffect(() => {
    if (query.get('trial_id') === 'null' || _.isEmpty(query.get('trial_id'))) {
      navigate('/portals')
    } else {
      dispatch(clearSilverPatientSummary())
      dispatch(clearPatientAppointment())
      dispatch(clearAppointmentPdf())
      dispatch(clearMatchedPatientTrialCount())
      dispatch(clearPartiallyMatchedPatientTrialCount())
      dispatch(clearGoldListPdf())
      dispatch(clearSilverListPdf())

      /* silver list */
      const silverPayloadData = {
        clinic_id: query.get('clinic_id'),
        trial_id: query.get('trial_id'),
        search: '',
        page: 1,
        pageLimit: 10,
      }
      dispatch(getTrialSilverListData_v1(silverPayloadData, token))

      /* silver list summary  */
      const silverSummaryPayloadData = {
        clinic_id: query.get('clinic_id'),
        trial_id: query.get('trial_id'),
      }
      dispatch(getSilverListSummary_v1(silverSummaryPayloadData, token))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!silverMatchesPdf) {
      handlePdfDownload()
    }
  }, [silverMatchesPdf])

  const cardData = [
    {
      title: 'Screened Patients',
      subTitle: silverPatientSummary?.total_patients ?? 0,
      description: null,
      color: variables.rose400,
      popup: false,
      popupFn: null,
      icon: <TroubleshootIcon sx={{ color: variables.rose400 }} />,
    },
    {
      title: 'Partial Matches',
      subTitle: silverPatientSummary?.num_eligible_patients ?? 0,
      description: null,
      color: variables.orange500,
      popup: false,
      popupFn: null,
      icon: <PersonAddAlt1Icon sx={{ color: variables.orange500 }} />,
    },
    {
      title: 'Upcoming Appointments',
      subTitle: silverPatientSummary?.appointments_count ?? 0,
      description: null,
      color: variables.purple,
      popup: false,
      // popupFn: () => {
      //   dispatch(clearPatientAppointment())
      //   dispatch(clearAppointmentPdf())
      //   dispatch(
      //     setPatientAppointment({
      //       title: partiallyMatchedPatientList?.studyName,
      //       listType: 'partial matches',
      //       list: partiallyMatchedPatientList?.appointments,
      //     })
      //   )
      //   dispatch(toggleAppointmentModal(true))
      // },
      icon: <EventAvailableIcon sx={{ color: variables.purple }} />,
    },
  ]

  const tableHeaderData = [
    { name: 'Name', align: null },
    { name: 'DOB', align: 'right' },
    { name: 'Phone Number', align: 'right' },
    { name: 'Upcoming Appointment', align: 'right' },
    { name: 'Status', align: 'right' },
  ]

  const patientPartialMatchListData = silverPatientList?.patients?.map((item) => {
    return {
      patientId: item?.patient_id,
      name: item?.name,
      dob: item?.date_of_birth,
      phNo: item?.phone_number,
      future_appt_date: item?.future_appt_date,
      patient_statuses: item?.patient_statuses,
      showPopup: true,
      popupFn: () => {
        dispatch(clearPatientInfoData())
        dispatch(togglePatientInfoModal(true))
        dispatch(
          setPatientInfoData({
            ...item,
            title: `${silverPatientList?.trial_name} Matching Trial Criteria`,
          })
        )
      },
    }
  })

  const handlePdfDownload = () => {
    setDownloadPdfFlag(true)
    // silverMatchesPdf is the data received from the api response
    try {
      // Decode the base64 string
      const binaryString = window.atob(silverMatchesPdf)

      // Convert the binary string to an array buffer
      const bytes = new Uint8Array(binaryString.length)
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      // Create a Blob from the array buffer
      const blob = new Blob([bytes.buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'silver_clinical_trials_results.xlsx'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (err) {
      console.log('ERROR in Handle document PDF', err)
      dispatch(
        setAlert({
          alertType: 'error',
          alertMsg: `Error in downloading PDF. Please try again.`,
          alertOpen: true,
        })
      )
    } finally {
      setDownloadPdfFlag(false)
    }
  }

  return (
    <>
      <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
        <AppBar hideSearch={true} />
        <Container maxWidth="lg">
          <Box className="my-1">
            <StepperTabs setValue={setStepValue} value={stepValue} />
          </Box>

          <Box className="d-flex justify-content-between align-items-center">
            <Box className="my-1 d-flex align-items-center justify-content-start px-1">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  dispatch(clearSilverPatientSummary())
                  dispatch(clearMatchedPatient())
                  dispatch(clearPartiallyMatchedPatient())

                  dispatch(clearPatientAppointment())
                  dispatch(clearAppointmentPdf())
                  dispatch(clearMatchedPatientTrialCount())
                  dispatch(clearPartiallyMatchedPatientTrialCount())
                  //dispatch(clearPatientInfoData()) // clear patient info data-not sure
                  dispatch(clearSilverPatient())
                  dispatch(clearGoldListPdf())
                  dispatch(clearSilverListPdf())
                  navigate(
                    `/screenflow-step-2?trial_id=${query.get('trial_id')}&clinic_id=${query.get('clinic_id')}`
                  )
                }}
                aria-label="close"
              >
                <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
              </IconButton>
              <Typography className="fs-5 fw-bold">Review Eligible Patient Summary</Typography>
            </Box>
          </Box>
          <Box className="my-2">
            <Box
              component={Paper}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: variables.gray200,
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                p: 1.5,

                minHeight: '10.19rem',
              }}
            >
              {!!silverPatientSummaryLoading ? (
                <Box
                  sx={{
                    minHeight: '8rem',
                  }}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <Loading />
                  <Typography className="gray-600 fw-normal fs-7 pb-2">
                    Fetching Summary Criteria...
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography className="fs-6 fw-bold bg-gray-600 mb-2">
                    {silverPatientSummary?.trial_name} Criteria Summary
                  </Typography>
                  <Divider className="mb-2" sx={{ backgroundColor: variables.gray600 }} />
                  <TrialCriteriaSummary cardData={cardData} />
                </>
              )}
            </Box>
          </Box>

          <Box className="pb-1 mt-1">
            <Box
              component={Paper}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: variables.gray200,
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                p: 1.5,
                minHeight: '20vh',
              }}
            >
              <Typography className="fs-6 fw-bold bg-gray-600 mb-2 text-capitalize">
                {silverPatientList?.trial_name} Trial List
              </Typography>
              <Divider className="mb-1 mt-1" sx={{ backgroundColor: variables.gray600 }} />
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{ minHeight: '3rem' }}
              >
                <Typography className="fs-6 fw-bold bg-gray-600">Partial Matches</Typography>
                <Chip
                  variant="filled"
                  sx={{
                    backgroundColor: variables.gray200,
                    color: variables.orange500,
                    fontWeight: 600,
                  }}
                  label={silverPatientList?.num_eligible_patients ?? 0}
                  size="small"
                />
                <Tooltip title="Download partial matches list">
                  <Button
                    sx={{ width: '9rem !important' }}
                    size="medium"
                    disabled={!!silverMatchesPdfLoading}
                    onClick={() => {
                      /* silver list pdf */
                      const silverPdfPayloadData = {
                        clinic_id: query.get('clinic_id'),
                        trial_id: query.get('trial_id'),
                        download: true,
                      }
                      dispatch(getTrialSilverListPdf_v1(silverPdfPayloadData, token))
                    }}
                    variant="contained"
                    className="btn-secondary"
                    startIcon={!!downloadPdfFlag ? <HourglassBottomIcon /> : <DownloadForOfflineIcon />}
                  >
                    {!!downloadPdfFlag ? 'Processing ...' : 'Download'}
                  </Button>
                </Tooltip>
              </Stack>
              <Divider className="mb-1" sx={{ backgroundColor: variables.gray600 }} />
              <Box
                sx={{
                  width: '100%',

                  my: 2,
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <TextField
                  fullWidth
                  id="appointment-search"
                  placeholder="Search Patient"
                  variant="outlined"
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      py: '3.5px !important',
                      '& fieldset': {
                        borderColor: variables.gray200,
                        borderRadius: '8px',
                        borderWidth: '2px !important',
                      },
                      '&:hover fieldset': {
                        borderColor: variables.indigo300,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: variables.indigo500,
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      </>
                    ),
                    endAdornment:
                      !!patientSearch || !!inputSearch ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="delete search value"
                            onClick={() => {
                              setPage(0)
                              setPatientSearch('')
                              setInputSearch('')

                              const silverPayloadData = {
                                clinic_id: query.get('clinic_id'),
                                trial_id: query.get('trial_id'),
                                search: '',
                                page: 1,
                                pageLimit: 10,
                              }
                              dispatch(getTrialSilverListData_v1(silverPayloadData, token))
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  value={inputSearch}
                  onChange={(e) => {
                    setInputSearch(e.target.value)
                  }}
                  onKeyUp={(event, newValue) => {
                    handlePartialMatchesPatientSearch(event)
                  }}
                />

                <Box>
                  <Box sx={{ pl: 1 }}>
                    <Button
                      // sx={{ float: '' }}
                      size="large"
                      onClick={() => {
                        setPage(0)
                        const silverPayloadData = {
                          clinic_id: query.get('clinic_id'),
                          trial_id: query.get('trial_id'),
                          search: inputSearch,
                          page: 1,
                          pageLimit: 10,
                        }
                        dispatch(getTrialSilverListData_v1(silverPayloadData, token))
                      }}
                      variant="contained"
                      className="btn-secondary"
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </Box>
              <PatientListTable
                tableHeaderData={tableHeaderData}
                loading={silverPatientListLoading}
                tableBodyData={patientPartialMatchListData}
              />
            </Box>
            {!!silverPatientListLoading ? (
              <Box sx={{ my: 4 }}> </Box>
            ) : (
              <TablePagination
                labelDisplayedRows={(from = page) =>
                  `Displaying ${from.count > 10 ? `${from.from} - ` : ''}${
                    from.to === -1 ? from.count : from.to
                  } out of ${from.count} results`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                component="div"
                count={partiallyMatchedPatientList?.resultCount ?? 0}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Displaying"
                SelectProps={{
                  inputProps: {
                    'aria-label': 'Displaying',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </Box>
          <Box className="d-flex justify-content-end pb-4 mx-2">
            <Button
              size="large"
              onClick={() => {
                dispatch(clearMatchedPatient())
                dispatch(clearPartiallyMatchedPatient())

                dispatch(clearPatientAppointment())
                dispatch(clearAppointmentPdf())
                dispatch(clearPatientInfoData())
                dispatch(clearMatchedPatientTrialCount())
                dispatch(clearPartiallyMatchedPatientTrialCount())
                dispatch(clearSilverPatient())
                dispatch(clearSilverPatientSummary())
                dispatch(clearGoldListPdf())
                dispatch(clearSilverListPdf())
                navigate('/screenflow')
                window.scrollTo(0, 0)
              }}
              variant="contained"
              className="btn-secondary"
              startIcon={<HomeIcon />}
            >
              Back to Clinical Trial Page
            </Button>
          </Box>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  login: state.loginData.loginResponse,
  token: state.loginData.loginResponse.idToken,

  partiallyMatchedPatientList: state.screenFlowPortalClinicalTrial.partiallyMatchedPatientList,

  silverPatientList: state.screenFlowPortalClinicalTrial.silverPatientList,
  silverPatientListLoading: state.screenFlowPortalClinicalTrial.silverPatientListLoading,
  silverPatientSummary: state.screenFlowPortalClinicalTrial.silverPatientSummary,
  silverPatientSummaryLoading: state.screenFlowPortalClinicalTrial.silverPatientSummaryLoading,
  silverMatchesPdf: state.screenFlowPortalClinicalTrial.silverMatchesPdf,
  silverMatchesPdfLoading: state.screenFlowPortalClinicalTrial.silverMatchesPdfLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getPartiallyMatchedPatientList: (payload, token) =>
      dispatch(getPartiallyMatchedPatientList(payload, token)),
    toggleAppointmentModal: (payload) => dispatch(toggleAppointmentModal(payload)),
    togglePatientInfoModal: (payload) => dispatch(togglePatientInfoModal(payload)),
    setPatientInfoData: (payload) => dispatch(setPatientInfoData(payload)),
    clearMatchedPatient: () => dispatch(clearMatchedPatient()),
    clearPartiallyMatchedPatient: () => dispatch(clearPartiallyMatchedPatient()),
    clearPatientAppointment: () => dispatch(clearPatientAppointment()),
    getTrialSilverListData_v1: (payload, token) => dispatch(getTrialSilverListData_v1(payload, token)),
    clearSilverPatient: () => dispatch(clearSilverPatient()),
    getSilverListSummary_v1: (payload, token) => dispatch(getSilverListSummary_v1(payload, token)),
    clearSilverPatientSummary: () => dispatch(clearSilverPatientSummary()),
    getTrialSilverListPdf_v1: (payload, token) => dispatch(getTrialSilverListPdf_v1(payload, token)),
    clearGoldListPdf: () => dispatch(clearGoldListPdf),
    clearSilverListPdf: () => dispatch(clearSilverListPdf),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFlow)
