import * as React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import variables from '../../../theme/variables.scss'
import IconButton from '@mui/material/IconButton'
import CallMadeIcon from '@mui/icons-material/CallMade'
import Checkbox from '@mui/material/Checkbox'
import _ from 'lodash'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: variables.gray50,
        // color: variables.gray500,
        textTransform: 'capitalize',
        fontSize: '0.75rem !important',
        fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
}))

export default function SummaryCohortTable({ tableHeaderData, tableBodyData, checkboxFlag = false }) {
    const calculateHeight = (id) => {
        return `${document.getElementById(`table-row-${id}`)?.getBoundingClientRect()?.height}px`
    }
    return (
        <TableContainer
            component={Paper}
            sx={{
                fontSize: '14px',
                borderRadius: '11px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                minHeight: 'calc(100vH - 250px)',
            }}
        >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeaderData?.map((header, index) => {
                            return (
                                <StyledTableCell
                                    align={header?.align ?? 'left'}
                                    sx={{
                                        color: `${
                                            !!header?.color ? header?.color : 'currentcolor'
                                        } !important`,
                                    }}
                                    key={index}
                                >
                                    {header.name} {!!header?.description ? header?.description : null}
                                </StyledTableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {/* no data condiiton */}
                        {!!(tableBodyData?.summary?.length === 0 || _.isEmpty(tableBodyData?.summary)) ? (
                            <TableRow>
                                <TableCell sx={{ border: 'none !important' }} colSpan={4}>
                                    <Typography
                                        sx={{
                                            color: variables.black200,

                                            textAlign: 'center',
                                            fontSize: '14px',
                                            padding: '20px',
                                        }}
                                    >
                                        No Data Available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {tableBodyData?.summary?.map((row) => (
                                    <StyledTableRow key={row.name} id={`table-row-${row?.id}`}>
                                        {!!checkboxFlag ? (
                                            <StyledTableCell>
                                                <Checkbox />
                                            </StyledTableCell>
                                        ) : null}
                                        <StyledTableCell
                                            width={'70%'}
                                            component="th"
                                            scope="row"
                                            sx={{
                                                position: 'relative',
                                                fontSize: '0.875rem !important',
                                                py: '32px !important',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    padding: '4px 0px',
                                                }}
                                            >
                                                {!!row?.id ? (
                                                    <Chip
                                                        label={row?.id}
                                                        sx={{
                                                            // minWidth: '60px',
                                                            mr: 1,
                                                            textTransform: 'capitalize',
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            color: variables.white100,
                                                            backgroundColor: variables.blue600,
                                                        }}
                                                    />
                                                ) : null}
                                                <Box>{row?.name}</Box>
                                            </Box>
                                            {!!row?.criteriaOperator ? (
                                                <Chip
                                                    label={row?.criteriaOperator}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        position: 'absolute',
                                                        left: '1%',
                                                        top: `calc(
                                                        ${calculateHeight(row?.id)} - 16px)`,
                                                        // top: calculateHeight(row?.id),
                                                        // top: '84%',
                                                        zIndex: 999,
                                                        width: '3.5rem !important',
                                                        color: variables.gray900,
                                                        backgroundColor: variables.white100,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        fontWeight: '400 !important',
                                                        fontSize: '0.75rem',
                                                        textTransform: 'uppercase',
                                                    }}
                                                />
                                            ) : null}
                                        </StyledTableCell>

                                        <StyledTableCell
                                            sx={{
                                                color: variables.gray500,
                                                fontWeight: '400',
                                                fontSize: '0.857rem',
                                            }}
                                            width={'15%'}
                                            align="center"
                                        >
                                            {row?.matches?.value ?? 'N/A'}
                                            {!!row?.matches?.popUp ? (
                                                <IconButton
                                                    aria-label="open-popup"
                                                    onClick={() => {
                                                        row?.popupFn()
                                                    }}
                                                    disabled={row?.matches?.disabledFlag}
                                                >
                                                    <CallMadeIcon
                                                        sx={{
                                                            color: variables.green500,
                                                            cursor: 'pointer',
                                                            width: '12px',
                                                            height: '12px',
                                                        }}
                                                    />
                                                </IconButton>
                                            ) : null}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            width={'15%'}
                                            align="center"
                                            sx={{ color: variables.gray500, fontWeight: '400' }}
                                        >
                                            {row?.partialMatches?.value ?? 'N/A'}
                                            {!!row?.partialMatches?.popUp ? (
                                                <IconButton
                                                    aria-label="open-popup"
                                                    onClick={() => {
                                                        row?.popupFn()
                                                    }}
                                                    disabled={row?.partialMatches?.disabledFlag}
                                                >
                                                    <CallMadeIcon
                                                        sx={{
                                                            color: variables.orange500,
                                                            cursor: 'pointer',
                                                            width: '12px',
                                                            height: '12px',
                                                        }}
                                                    />
                                                </IconButton>
                                            ) : null}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        )}
                    </>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
