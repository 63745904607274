import { useAuthenticator, useTheme, View, Image } from '@aws-amplify/ui-react'
import React from 'react'

export const loginComponents = {
    Header() {
        const { tokens } = useTheme()

        return (
            <>
                <View textAlign="center" padding={tokens.space.large}>
                    <Image className="image-fluid main-logo" alt="Flow logo" src="/images/logo.svg" />
                </View>
                <div className="text-center mb-3">
                    <img
                        className="image-fluid logo-slogan"
                        src="/images/logo-slogan.svg"
                        alt="flow logo slogan"
                    />
                </div>
            </>
        )
    },

    Footer() {
        const { tokens } = useTheme() // eslint-disable-line no-unused-vars

        return (
            <div className="mt-3 d-flex align-items-center justify-content-center fw-bold">
                <img src="/images/logo-icon-small.svg" alt="logo icon small" />
                <div className="mx-3 text-cardia-health">
                    A product by <span className="pl-2 tx-primary">CARDEA HEALTH</span>
                </div>
            </div>
        )
    },

    SignIn: {
        Footer() {
            const { toResetPassword } = useAuthenticator() // eslint-disable-line no-unused-vars

            return (
                <div className="my-4 fw-bold d-flex justify-content-evenly">
                    <a
                        href="https://cardeahealth.io/#cardea-team"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <span style={{ textDecoration: 'underline' }}>FAQs</span>
                        <span>{' >'}</span>
                    </a>
                    <div>
                        <a
                            href="mailto:flow-support@cardeahealth.io"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                            rel="noreferrer"
                        >
                            <span style={{ textDecoration: 'underline' }}>Customer Support</span>
                            <span>{' >'}</span>
                        </a>
                    </div>
                </div>
            )
        },
    },

    ConfirmResetPassword: {},
}

export const loginFormFields = {
    signIn: {
        username: {
            labelHidden: true,
            placeholder: 'Email Address',
        },
        password: {
            labelHidden: true,
            placeholder: 'Enter your Password',
        },
    },
    forceNewPassword: {
        password: {
            labelHidden: true,
            placeholder: 'Enter your Password:',
        },
    },
    resetPassword: {
        username: {
            labelHidden: true,
            placeholder: 'Enter your email:',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: true,
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
        },
        confirm_password: {
            labelHidden: true,
            placeholder: 'Enter your Password Please:',
        },
    },
    setupTOTP: {
        QR: {
            totpIssuer: 'Cardea Health',
            totpUsername: 'Flow Web',
        },
        confirmation_code: {
            labelHidden: true,
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: true,
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
}
