import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import variables from '../../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import { comparisonOperatorLabel } from '../../../util/index'
const ReviewCohortTable = ({ data }) => {
    return (
        <Box>
            <Grid container columnSpacing={0} rowSpacing={12} wrap="wrap">
                {data?.criteria?.map((item, index) => (
                    <>
                        <Grid key={index} item xs={12} sm={6} md={4}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: variables.gray50,
                                    color: variables.gray500,
                                    textTransform: 'uppercase',
                                    border: `1px solid ${variables.lightGray1}`,
                                    p: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: variables.black200,
                                        fontSize: '0.875rem',
                                        fontWeight: '500 !important',
                                    }}
                                >
                                    Criterion #{index + 1}
                                </Typography>
                                {item?.criteriaOperator ? (
                                    <Chip
                                        label={item?.criteriaOperator}
                                        variant="outlined"
                                        color="info"
                                        sx={{
                                            position: 'absolute',
                                            left: '93%',
                                            top: '20%',
                                            zIndex: 999,
                                            width: '3.5rem !important',
                                            color: variables.gray900,
                                            backgroundColor: variables.white100,
                                            border: `1px solid ${variables.lightGray1}`,
                                            fontWeight: '400 !important',
                                            fontSize: '0.75rem',
                                        }}
                                    />
                                ) : null}
                            </Box>
                            <Box
                                style={{
                                    padding: 16,
                                    backgroundColor: variables.white100,
                                    border: `1px solid ${variables.lightGray1}`,
                                    borderTop: 'none',
                                    height: '100%',
                                }}
                            >
                                {item?.criterionValues?.map((criteriaValue, idx) => (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',

                                                gap: 1.25,
                                                p: 2,
                                            }}
                                        >
                                            {[
                                                'Medical History',
                                                'Medication',
                                                'Labs',
                                                'Demographics',
                                            ].includes(criteriaValue?.category) ? (
                                                <Chip
                                                    label={criteriaValue?.category ?? 'N/A'}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        color: variables.white100,
                                                        backgroundColor: variables.purple,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        fontWeight: '400 !important',
                                                        fontSize: '0.75rem',
                                                    }}
                                                />
                                            ) : (
                                                <Typography className="fs-8 px-1" component={'span'}>
                                                    {criteriaValue?.category ?? 'N/A'}
                                                </Typography>
                                            )}
                                            {_.isEmpty(criteriaValue?.status) ? null : (
                                                <Chip
                                                    label={criteriaValue?.status ?? 'N/A'}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        minWidth: '3.5rem !important',
                                                        color: variables.gray900,
                                                        backgroundColor: variables.white100,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        fontWeight: '400 !important',
                                                        fontSize: '0.75rem',
                                                        textTransform: 'capitalize',
                                                    }}
                                                />
                                            )}
                                            {['Medical History', 'Medication'].includes(
                                                criteriaValue?.category
                                            ) ? (
                                                <Typography className="fs-8" component={'span'}>
                                                    of
                                                </Typography>
                                            ) : null}
                                            <Chip
                                                label={criteriaValue?.marker ?? 'N/A'}
                                                variant="outlined"
                                                color="info"
                                                sx={{
                                                    minWidth: '3.5rem !important',
                                                    color: variables.gray900,
                                                    backgroundColor: variables.lightPurple,
                                                    border: `1px solid ${variables.lightGray1}`,
                                                    borderRadius: '5px',
                                                    fontSize: '0.75rem',
                                                    fontWeight: 600,
                                                    textTransform: 'uppercase',
                                                }}
                                            />
                                        </Box>
                                        {_.isEmpty(criteriaValue?.value) ? null : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1.25,
                                                    px: 2,
                                                    py: 1,
                                                }}
                                            >
                                                <Typography className="fs-8" component={'span'}>
                                                    Value
                                                </Typography>

                                                <Chip
                                                    label={comparisonOperatorLabel(
                                                        criteriaValue?.comparisonOperator
                                                    )}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        minWidth: '3.5rem !important',
                                                        color: variables.gray900,
                                                        backgroundColor: variables.white100,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        fontWeight: '400 !important',
                                                        fontSize: '0.75rem',
                                                        textTransform: 'uppercase',
                                                    }}
                                                />
                                                <Chip
                                                    label={criteriaValue?.value ?? 'N/A'}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        minWidth: '3.5rem !important',
                                                        color: variables.gray900,
                                                        backgroundColor: variables.lightPurple,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        borderRadius: '5px',
                                                        fontSize: '0.75rem',
                                                        fontWeight: 600,
                                                        textTransform: 'uppercase',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {_.isEmpty(criteriaValue?.criterionOperator) ? null : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    px: 2,
                                                }}
                                            >
                                                <Chip
                                                    label={criteriaValue?.criterionOperator}
                                                    variant="outlined"
                                                    color="info"
                                                    sx={{
                                                        minWidth: '3.5rem !important',
                                                        color: variables.gray900,
                                                        backgroundColor: variables.white100,
                                                        border: `1px solid ${variables.lightGray1}`,
                                                        fontWeight: '400 !important',
                                                        fontSize: '0.75rem',
                                                        textTransform: 'uppercase',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </>
                                ))}
                            </Box>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    )
}

export default ReviewCohortTable
