import {
    SET_COHORT_BUILDER_FORM_DATA,
    SET_COHORT_BUILDER_FORM_DATA_LOADING,
    CLEAR_COHORT_BUILDER_FORM_DATA,

    /* form response */
    SET_COHORT_FORM_RESPONSE,
    SET_COHORT_FORM_RESPONSE_LOADING,
    CLEAR_COHORT_FORM_RESPONSE,
} from './cohortBuilder.type'

const INITIAL_STATE = {
    cohortBuilderFormData: {},
    cohortBuilderFormDataLoading: false,
    cohortBuilderFormDataFailed: false,
    cohortFormResponse: {},
    cohortFormResponseLoading: false,
}

const cohortBuilderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COHORT_BUILDER_FORM_DATA:
            return {
                ...state,
                cohortBuilderFormData: action.payload,
            }
        case SET_COHORT_BUILDER_FORM_DATA_LOADING:
            return {
                ...state,
                cohortBuilderFormDataLoading: action.payload,
            }

        case CLEAR_COHORT_BUILDER_FORM_DATA:
            return {
                ...state,
                cohortBuilderFormData: {},
                cohortBuilderFormDataLoading: false,
                cohortBuilderFormDataFailed: false,
            }
        case SET_COHORT_FORM_RESPONSE:
            return {
                ...state,
                cohortFormResponse: action.payload,
            }
        case SET_COHORT_FORM_RESPONSE_LOADING:
            return {
                ...state,
                cohortFormResponseLoading: action.payload,
            }
        case CLEAR_COHORT_FORM_RESPONSE:
            return {
                ...state,
                cohortFormResponse: {},
                cohortFormResponseLoading: false,
            }
        default:
            return state
    }
}

export default cohortBuilderReducer
