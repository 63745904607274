import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppBar from '../../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../../components/portal-components/stepper-tabs/StepperTabs'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import HomeIcon from '@mui/icons-material/Home'
import variables from './../../theme/variables.scss'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { Divider, Typography } from '@mui/material'
import '../../components/social-history/social-form/social-form.scss'

import SummaryCohortTable from '../../components/portal-components/cohort-builder/SummaryCohortTable'
import { LabelWrapper } from '../../util/screen-flow-data'
import '../../util/screenflow-data.scss'
import { comparisonOperatorLabel } from '../../util/index'
export const CohortBuilderStep3 = ({ login, token, cohortBuilderFormData, cohortFormResponse }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })

    // on page render scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [stepValue, setStepValue] = React.useState('three')

    // for create all possible sentence structure of the sentence
    const sentenceMapping = (criterionValue) => {
        switch (criterionValue?.category) {
            case 'Medical History':
                return (
                    <Box component={'span'}>
                        <LabelWrapper
                            label={criterionValue?.category}
                            fontType="capitalise"
                            fontWeight="bold"
                        />
                        :{' '}
                        <Box component={'span'} sx={{ textTransform: 'capitalize !important' }}>
                            {criterionValue?.status}
                        </Box>{' '}
                        of <LabelWrapper label={criterionValue?.marker} fontType="upper" fontWeight="bold" />
                    </Box>
                )
            case 'Medication':
                return (
                    <Box component={'span'}>
                        <LabelWrapper
                            label={criterionValue?.category}
                            fontType="capitalise"
                            fontWeight="bold"
                        />
                        :{' '}
                        <Box component={'span'} sx={{ textTransform: 'capitalize !important' }}>
                            {criterionValue?.status}
                        </Box>{' '}
                        of <LabelWrapper label={criterionValue?.marker} fontType="upper" fontWeight="bold" />
                    </Box>
                )
            case 'Labs':
                return (
                    <Box component={'span'}>
                        <LabelWrapper
                            label={criterionValue?.category}
                            fontType="capitalise"
                            fontWeight="bold"
                        />
                        : <LabelWrapper label={criterionValue?.marker} fontType="upper" fontWeight="bold" />{' '}
                        {comparisonOperatorLabel(criterionValue?.comparisonOperator)}{' '}
                        <LabelWrapper label={criterionValue?.value} fontWeight="bold" /> mg/dL.{' '}
                    </Box>
                )
            case 'Demographics':
                return (
                    <Box component={'span'}>
                        <LabelWrapper
                            label={criterionValue?.category}
                            fontType="capitalise"
                            fontWeight="bold"
                        />
                        : <LabelWrapper label={criterionValue?.marker} fontType="upper" fontWeight="bold" />{' '}
                        {comparisonOperatorLabel(criterionValue?.comparisonOperator)}{' '}
                        <LabelWrapper label={criterionValue?.value} fontWeight="bold" /> years.{' '}
                    </Box>
                )
            default:
                return (
                    <Box component={'span'}>
                        <strong>{criterionValue?.category}</strong> : {criterionValue?.status}{' '}
                        {criterionValue?.marker} {comparisonOperatorLabel(criterionValue?.comparisonOperator)}{' '}
                        {criterionValue?.value}{' '}
                    </Box>
                )
        }
    }

    // Add the sentences to the criterion operator for the summary table using form response data and the internal state data
    const summaryData = () => {
        return cohortBuilderFormData?.criteria?.map((criterion, idx) => {
            return (
                <Box component={'div'} key={idx}>
                    {criterion?.criterionValues?.map((criterionValue, index) => {
                        return (
                            <>
                                <Box component={'div'} key={index}>
                                    {sentenceMapping(criterionValue)}
                                </Box>
                                {!!criterionValue?.criterionOperator ? (
                                    <Box
                                        component={'div'}
                                        key={index}
                                        sx={{ textTransform: 'uppercase', py: 0.3 }}
                                    >
                                        {criterionValue?.criterionOperator}
                                    </Box>
                                ) : null}
                            </>
                        )
                    })}
                </Box>
            )
        })
    }
    const compiledData = summaryData()

    //filter the data for the final summary table
    const filteredData = {
        info: (
            <Box component={'span'}>
                {'('}Screened Patients{' '}
                <Box
                    component={'span'}
                    sx={{ color: `${variables.purple} !important`, fontWeight: '700 !important' }}
                >
                    D={cohortFormResponse?.total_patient_count ?? 'NA'}
                </Box>
                , Eligible Patients{' '}
                <Box
                    component={'span'}
                    sx={{ color: `${variables.purple} !important`, fontWeight: '700 !important' }}
                >
                    N={cohortFormResponse?.eligible_patient_count ?? 'NA'}
                </Box>
                {')'}
            </Box>
        ),
        summary: cohortFormResponse?.criterion_results?.map((item, idx) => {
            const isLastItem = idx === cohortFormResponse?.criterion_results?.length - 1
            return {
                id: item.id,
                criteriaOperator: isLastItem ? null : item?.criterionOperator ?? 'NA',
                name: (
                    <>
                        <Box component={'span'}>{compiledData[idx]}</Box>
                    </>
                ),
                matches: {
                    value: item?.total_count ?? 'NA',
                    popUp: true,
                    popUpFn: null,
                    disabledFlag: true,
                },
                partialMatches: {
                    value: 0,
                    popUp: true,
                    popupFn: () => {},
                    disabledFlag: true,
                },
            }
        }),
    }

    const tableHeaderData = [
        {
            name: 'Criteria Summary',
            description: filteredData?.info,
            align: null,
            color: variables.gray500,
        },

        { name: 'Total Matches', description: null, align: 'center', color: variables.green500 },
        { name: 'Partial Matches', description: null, align: 'center', color: variables.orange500 },
    ]

    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar hideSearch={true} />
                <Container maxWidth="lg">
                    <Box className="my-1">
                        <StepperTabs setValue={setStepValue} value={stepValue} portalName="cohortBuilder" />
                    </Box>
                    <Box className="d-flex justify-content-between align-items-center py-1">
                        <Box className="my-1 d-flex align-items-center justify-content-start px-1">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    navigate(`/cohort-builder-step-2`)
                                }}
                                aria-label="close"
                            >
                                <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
                            </IconButton>
                            <Typography className="fs-5 fw-bold">Review Cohort Tiral Criteria</Typography>
                        </Box>
                    </Box>

                    <Box className="my-2">
                        {/* "pb-3 mt-1" */}
                        <Box
                            component={Paper}
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderColor: variables.gray200,
                                borderRadius: '8px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                                p: 1.5,
                                minHeight: '10.19rem',
                            }}
                        >
                            <Typography className="fs-6 fw-bold bg-gray-600 mb-2">
                                Criteria Summary
                            </Typography>
                            <Divider className="mb-2" sx={{ backgroundColor: variables.gray600 }} />
                            <Box>
                                <SummaryCohortTable
                                    tableHeaderData={tableHeaderData}
                                    tableBodyData={filteredData}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="d-flex justify-content-end" sx={{ py: 3 }}>
                        <Button
                            size="large"
                            onClick={() => {
                                navigate(`/portals`)
                            }}
                            variant="contained"
                            className="btn-secondary"
                            startIcon={<HomeIcon />}
                        >
                            Home Portal
                        </Button>
                    </Box>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.accessToken,
    cohortFormResponse: state.cohortBuilder.cohortFormResponse,
    cohortBuilderFormData: state.cohortBuilder.cohortBuilderFormData,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CohortBuilderStep3)
