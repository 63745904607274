import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StepIcon from '@mui/material/StepIcon'
import variables from '../../../theme/variables.scss'
import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTab = styled(Tab)({
    '&.Mui-selected': {
        color: variables.purple,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'none',
    color: variables.gray600,
    cursor: 'default !important',
})

const StyledStepLabel = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',
})

const StyledStepConnector = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const RightPointIcon = () => (
    <StyledStepConnector>
        <StepIcon icon={<ArrowForwardIosIcon sx={{ color: variables.gray200, height: '32px' }} />} />
    </StyledStepConnector>
)

export default function StepperTabs({ value, setValue, portalName = 'screenflow' }) {
    return (
        <Box
            component={Paper}
            sx={{
                width: '100%',
                border: '1px solid',
                borderColor: variables.gray200,
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            }}
        >
            {portalName === 'cohortBuilder' ? (
                <>
                    <Tabs
                        variant="fullWidth"
                        value={value}
                        aria-label="secondary tabs example"
                        sx={{
                            borderRadius: '8px',
                            textAlign: 'left',
                            '& .MuiTabs-indicator': {
                                backgroundColor: variables.purple,
                            },
                            '& .Mui-selected': {
                                color: variables.purple,
                            },
                        }}
                    >
                        <StyledTab
                            value={'one'}
                            label={
                                <StyledStepLabel>
                                    <Typography className="fw-bold">Step 1</Typography>
                                    <Typography className="fs-8">Build Cohort Trial Criteria</Typography>
                                </StyledStepLabel>
                            }
                            icon={
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                        visibility: value !== 'one' ? 'visible' : 'hidden',
                                    }}
                                />
                            }
                            iconPosition="end"
                        />
                        <RightPointIcon />
                        <StyledTab
                            value={'two'}
                            label={
                                <StyledStepLabel>
                                    <Typography className="fw-bold">Step 2</Typography>
                                    <Typography className="fs-8 ">Review Cohort Trial Criteria</Typography>
                                </StyledStepLabel>
                            }
                            icon={
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                        visibility: value !== 'one' && value !== 'two' ? 'visible' : 'hidden',
                                    }}
                                />
                            }
                            iconPosition="end"
                        />
                        <RightPointIcon />
                        <StyledTab
                            value={'three'}
                            label={
                                <StyledStepLabel>
                                    <Typography className="fw-bold">Step 3</Typography>
                                    <Typography className="fs-8">Eligible Patients Summary</Typography>
                                </StyledStepLabel>
                            }
                            icon={
                                <CheckCircleIcon
                                    sx={{
                                        color: variables.green500,
                                        visibility:
                                            value !== 'one' && value !== 'two' && value !== 'three'
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                />
                            }
                            iconPosition="end"
                        />
                    </Tabs>
                </>
            ) : (
                <Tabs
                    variant="fullWidth"
                    value={value}
                    aria-label="secondary tabs example"
                    sx={{
                        borderRadius: '8px',
                        textAlign: 'left',
                        '& .MuiTabs-indicator': {
                            backgroundColor: variables.purple,
                        },
                        '& .Mui-selected': {
                            color: variables.purple,
                        },
                    }}
                >
                    <StyledTab
                        value={'one'}
                        label={
                            <StyledStepLabel>
                                <Typography className="fw-bold">Step 1</Typography>
                                <Typography className="fs-8"> Select Clinical Trial</Typography>
                            </StyledStepLabel>
                        }
                        icon={
                            <CheckCircleIcon
                                sx={{
                                    color: variables.green500,
                                    visibility: value !== 'one' ? 'visible' : 'hidden',
                                }}
                            />
                        }
                        iconPosition="end"
                    />
                    <RightPointIcon />
                    <StyledTab
                        value={'two'}
                        label={
                            <StyledStepLabel>
                                <Typography className="fw-bold">Step 2</Typography>
                                <Typography className="fs-8">Find Eligible Patients</Typography>
                            </StyledStepLabel>
                        }
                        icon={
                            <CheckCircleIcon
                                sx={{
                                    color: variables.green500,
                                    visibility: value !== 'one' && value !== 'two' ? 'visible' : 'hidden',
                                }}
                            />
                        }
                        iconPosition="end"
                    />
                    <RightPointIcon />
                    <StyledTab
                        value={'three'}
                        label={
                            <StyledStepLabel>
                                <Typography className="fw-bold">Step 3</Typography>
                                <Typography className="fs-8 ">
                                    {' '}
                                    Review Partially Eligible Patients{' '}
                                </Typography>
                            </StyledStepLabel>
                        }
                        icon={<CheckCircleIcon sx={{ color: variables.green500, visibility: 'hidden' }} />}
                        iconPosition="end"
                    />
                </Tabs>
            )}
        </Box>
    )
}
