import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import AppBar from '../../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../../components/portal-components/stepper-tabs/StepperTabs'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import variables from './../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import Button from '@mui/material/Button'
import ReviewCohortTable from '../../components/portal-components/cohort-builder/ReviewCohortTable'
import { commonErrorHandler, networkErrorHandler } from '../../util'
import { setCohortFormResponse } from '../../redux/searchFlow/cohortBuilder/cohortBuilder.action'

const CohortBuilderStep2 = ({ login, token, cohortBuilderFormData }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [stepvalue, setStepValue] = React.useState('two')
    const [formLoading, setFormLoading] = React.useState(false) // form processing state

    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })

    // on page render scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleCohortFrom = async () => {
        setFormLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SCREENFLOW_BASE_URL}/${process.env.REACT_APP_ENVIRONMENT}/api/cohort/summary`,
                cohortBuilderFormData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            console.log('response', response)
            const { status, data, error } = response
            if (status === 200) {
                dispatch(setCohortFormResponse(data))
                navigate('/cohort-builder-step-3')
            } else {
                commonErrorHandler(dispatch, status, error, 'Cohort Form')
            }
        } catch (err) {
            console.log('err', err)
            if (!err.response) {
                // Network error
                networkErrorHandler(dispatch, err)
            } else {
                // HTTP error
                commonErrorHandler(dispatch, err?.response?.status, err?.response?.data, 'Cohort Form')
            }
        } finally {
            setFormLoading(false)
        }
    }
    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar searchType={'screenflow'} hideSearch={true} />

                <Container maxWidth="lg">
                    <Box className="my-1">
                        <StepperTabs value={stepvalue} setValue={setStepValue} portalName="cohortBuilder" />
                    </Box>
                    <Box className="my-1 d-flex align-items-center justify-content-start px-1 my-3">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                navigate('/cohort-builder-step-1')
                            }}
                            aria-label="close"
                            disabled={formLoading}
                        >
                            <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
                        </IconButton>
                        <Typography className="fs-5 fw-bold">Review Cohort - Cardea Health</Typography>
                    </Box>

                    <Box className="pb-4 mt-1 ">
                        <ReviewCohortTable data={cohortBuilderFormData} />
                    </Box>
                    <Box className="d-flex justify-content-end" sx={{ pt: 8, pb: 4 }}>
                        <Button
                            size="large"
                            onClick={handleCohortFrom}
                            variant="contained"
                            className="btn-secondary"
                            endIcon={!!formLoading ? <HourglassBottomIcon /> : <ArrowForwardIcon />}
                            disabled={formLoading}
                        >
                            {formLoading ? 'Processing Data...' : 'Find Eligible Patients Summary'}
                        </Button>
                    </Box>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.idToken,
    cohortBuilderFormData: state.cohortBuilder.cohortBuilderFormData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCohortFormResponse: (payload) => dispatch(setCohortFormResponse(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CohortBuilderStep2)
