import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppBar from '../../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../../components/portal-components/stepper-tabs/StepperTabs'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import variables from './../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import CohortForm from '../../components/portal-components/cohort-builder/form/CohortForm'
import {
    clearCohortBuilderFormData,
    clearCohortFormResponse,
} from '../../redux/searchFlow/cohortBuilder/cohortBuilder.action'

const CohortBuilderStep1 = ({ login, token }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [stepvalue, setStepValue] = React.useState('one')

    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })

    // on page render scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar
                    searchPlaceholder={'Search Clinical Trials'}
                    searchType={'screenflow'}
                    hideSearch={true}
                />

                <Container maxWidth="lg">
                    <Box className="my-1">
                        <StepperTabs value={stepvalue} setValue={setStepValue} portalName="cohortBuilder" />
                    </Box>
                    <Box className="my-1 d-flex align-items-center justify-content-start px-1 my-3">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                navigate('/portals/searchflow')
                                dispatch(clearCohortBuilderFormData())
                                dispatch(clearCohortFormResponse())
                            }}
                            aria-label="close"
                        >
                            <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
                        </IconButton>
                        <Typography className="fs-5 fw-bold">Build Cohort - Cardea Health</Typography>
                    </Box>

                    <Box className="pb-4 mt-1 ">
                        <CohortForm />
                    </Box>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {
        clearCohortBuilderFormData: () => dispatch(clearCohortBuilderFormData()),
        clearCohortFormResponse: () => dispatch(clearCohortFormResponse()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CohortBuilderStep1)
