import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AppBar from '../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../components/portal-components/stepper-tabs/StepperTabs'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import ClinicalTrialTable from '../components/portal-components/ClinicalTrialTable'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import variables from './../theme/variables.scss'
import TablePagination from '@mui/material/TablePagination'
import { TablePaginationActions } from '../components/appointment-list/index'
import Typography from '@mui/material/Typography'

import {
  // getTrialListData,
  clearTrial,
  getClinicListData_v1,
  getTrialListData_v1,
} from '../redux/searchFlow/screenFlow/screenFlow.action'

const tableHeaderData = [
  { name: 'Study Title', align: null },
  { name: 'Sponsor', align: 'right' },
  { name: 'Eligibility Criteria', align: 'right' },
  { name: 'ARM', align: 'right' },
]

function SimpleDropdown({ dropDownList, onChangeHandler, labelValue, defaultValue }) {
  const [value, setValue] = useState(defaultValue ?? '')

  return (
    <>
      {labelValue && (
        <Typography className="fs-8" component={'span'}>
          {labelValue}
        </Typography>
      )}
      <Select
        value={value ?? defaultValue}
        onChange={(e) => {
          setValue(e.target.value)
          onChangeHandler(e.target.value)
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: variables.gray200,
              borderRadius: '8px',
              borderWidth: '2px !important',
            },
            '&:hover fieldset': {
              borderColor: variables.indigo300,
            },
            '&.Mui-focused fieldset': {
              borderColor: variables.indigo500,
            },
            '&.Mui-disabled fieldset': {
              borderColor: variables.gray200,
              backgroundColor: variables.whiteTransparent,
              pointerEvents: 'initial !important',
              cursor: 'not-allowed !important',
            },
          },
        }}
      >
        {dropDownList.map((dropDownItem) => (
          <MenuItem value={dropDownItem.value}>{dropDownItem.name}</MenuItem>
        ))}
      </Select>
    </>
  )
}

export const ScreenFlow = ({
  login,
  token,
  clinicList,
  clinicListLoading,
  clinicalTrialList,
  clinicalTrialListLoading,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const clinicListOptionArray = clinicList.map((clinic) => ({
    name: clinic.name,
    value: clinic.id,
  }))

  const [clinicId, setClinicId] = useState('1') // 1 is default
  const [togglePopUp, setTogglePopUp] = useState(false)
  const [stepvalue, setStepValue] = useState('one')

  useEffect(() => {
    if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
      navigate('/')
    }
  })

  useEffect(() => {
    const payloadData = {
      // page: 1,
      // pageLimit: 10,
      // searchKey: '',
      searchTerm: '',
    }
    dispatch(getClinicListData_v1(payloadData, token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!clinicId) {
      return
    }

    const payloadData = {
      // page: 1,
      // pageLimit: 10,
      // searchKey: '',
      searchTerm: '',
      clinic_id: clinicId,
    }
    dispatch(getTrialListData_v1(payloadData, token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId])

  const onClinicSelectHandler = (value) => {
    setClinicId(value)
  }

  return (
    <>
      <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
        <AppBar searchPlaceholder={'Search Clinical Trials'} searchType={'screenflow'} hideSearch={false} />

        <Container maxWidth="lg">
          <Box className="my-1">
            <StepperTabs value={stepvalue} setValue={setStepValue} />
          </Box>

          <Box className="my-1 d-flex align-items-center justify-content-start px-1 my-3">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                navigate('/portals')
                dispatch(clearTrial())
              }}
              aria-label="close"
            >
              <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
            </IconButton>
            <Typography className="fs-5 fw-bold">Clinical Trial Screener</Typography>
          </Box>

          <Box className="pb-3 mt-1">
            <Box
              component={Paper}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: variables.gray200,
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                p: 2,
                display: 'flex',
                justifyContent: 'start',
                gap: 4,
              }}
            >
              <Box sx={{ width: '50%' }}>
                <Typography className="gray-600 fs-6 fw-normal pb-2">Clinic</Typography>
                <Tooltip
                  arrow
                  title="Filtering patient results according to the current logged in provider"
                  followCursor
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 24],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <SimpleDropdown
                    dropDownList={clinicListOptionArray}
                    onChangeHandler={onClinicSelectHandler}
                    // labelValue="Clinic"
                    defaultValue={'1'}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <Box className="pb-4 mt-1">
            <ClinicalTrialTable
              tableHeaderData={tableHeaderData}
              loading={clinicListLoading || clinicalTrialListLoading}
              tableBodyData={clinicalTrialList}
              togglePopUp={togglePopUp}
              setTogglePopUp={setTogglePopUp}
            />
            <TablePagination
              labelDisplayedRows={(from = 1) =>
                `Displaying ${clinicalTrialList?.length ?? 0} out of ${
                  clinicalTrialList?.length ?? 0
                } results`
              }
              component="div"
              count={10}
              rowsPerPageOptions={[]}
              rowsPerPage={10}
              page={0}
              labelRowsPerPage="Displaying"
              SelectProps={{
                inputProps: {
                  'aria-label': 'Displaying',
                },
                native: true,
              }}
              // onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  login: state.loginData.loginResponse,
  token: state.loginData.loginResponse.idToken,
  clinicList: state.screenFlowPortalClinicalTrial.clinicList,
  clinicListLoading: state.screenFlowPortalClinicalTrial.clinicListLoading,
  clinicalTrialList: state.screenFlowPortalClinicalTrial.clinicalTrialList,
  clinicalTrialListLoading: state.screenFlowPortalClinicalTrial.clinicalTrialListLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getClinicListData_v1: (payload, token) => dispatch(getClinicListData_v1(payload, token)),
    getTrialListData_v1: (payload, token) => dispatch(getTrialListData_v1(payload, token)),
    clearTrial: () => dispatch(clearTrial()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFlow)
