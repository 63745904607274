import * as React from 'react'
import Box from '@mui/material/Box'
import variables from '../theme/variables.scss'
import './screenflow-data.scss'

export const LabelWrapper = ({ label, fontType = null, fontWeight = 'normal' }) => {
    const fontTypeMapping = () => {
        switch (fontType) {
            case 'upper':
                return 'uppercase'
            case 'lower':
                return 'lowercase'
            case 'capitalize':
                return 'capitalize'
            default:
                return 'none'
        }
    }
    const fontWeightMapping = () => {
        switch (fontWeight) {
            case 'bold':
                return 'bold'
            case 'normal':
                return 'normal'
            default:
                return 'normal'
        }
    }
    return (
        <Box
            component="span"
            sx={{
                color: variables.gray800,
                backgroundColor: variables.lightPurple,
                px: 0.2,
                mr: 0.2,
                borderRadius: '7%',
                textTransform: fontTypeMapping(),
                fontWeight: fontWeightMapping(),
            }}
        >
            {label ?? ''}
        </Box>
    )
}

/* placeholder data */
export const trialList = [
    {
        name: 'zeus',
        eligibility: 'eligible',
        dateCreated: null,
        dateUpdated: null,
        inclusion: {
            flag: true,
            value: 3,
        },
        exclusion: {
            flag: true,
            value: 4,
        },
        id: 'ze1',
        info: {
            title: 'A Research Study to Look at How Ziltivekimab Works Compared to Placebo in People With Cardiovascular Disease, Chronic Kidney Disease and Inflammation (ZEUS).',
            sponsor: 'Novo Nordisk A/S',
            objective:
                'If Ziltivekimab can reduce the risk of having cardiovascular events (for example heart attack and stroke) in people with cardiovascular disease, chronic kidney disease and inflammation.',
            studyArm: [
                'Experimental: Ziltivekimab 15mg (up to 4 years).',
                'Placebo Comparator: Placebo (up to 4 years).',
            ],
        },
        inclusionCriteriaData: [
            {
                id: 'eGFR',
                criteria: (
                    <>
                        <LabelWrapper label="CKD" />
                        defined by one of these:
                        <ol>
                            <li>
                                <LabelWrapper
                                    label={`
                                    eGFR: >= 15`}
                                />{' '}
                                and{' '}
                                <LabelWrapper
                                    label={`
                                    < 60`}
                                />
                                mL/min/1.73 m<sup>2</sup>
                            </li>
                            <li>
                                <LabelWrapper label={`UACR: >= 200`} />
                                mg/g and <LabelWrapper label={` eGFR: >= 60`} />
                                mL/min/1.73 m<sup>2</sup>
                            </li>
                        </ol>
                    </>
                ),
                observedValue: (
                    <div>
                        eGFR = 43 mL/min/1.73 m<sup>2</sup>
                    </div>
                ),
                date: <div>July 09, 2022</div>,
            },
            {
                id: 'crp',
                criteria: (
                    <>
                        <LabelWrapper label={`hs-CRP: >= 2`} />
                        mg/L
                    </>
                ),
                observedValue: <div>hs-CRP = 4 mg/L</div>,
                date: <div>July 09, 2022</div>,
            },

            {
                id: 'evidenceOfASCVD',
                criteria: (
                    <div>
                        Evidence of <LabelWrapper label="ASCVD" /> by one or more of these:
                        <ol>
                            <li>
                                <LabelWrapper label="Coronary heart disease" /> such as one of these:
                                <ol type="a">
                                    <li>
                                        History of <LabelWrapper label="MI" />
                                    </li>
                                    <li>
                                        Prior <LabelWrapper label="coronary revascularisation" />
                                    </li>
                                    <li>
                                        <LabelWrapper label=">= 50% stenosis" /> in major epicardial{' '}
                                        <LabelWrapper label="coronary artery" />
                                        documented by cardiac cath or CT coronary angio.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <LabelWrapper label="Cerebrovascular disease" /> as one of these:
                                <ol type="a">
                                    <li>
                                        Prior <LabelWrapper label="stroke" /> of atherosclerotic origin{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        Prior <LabelWrapper label="carotid artery revascularisation" />
                                    </li>
                                    <li>
                                        <LabelWrapper label=">= 50% stenosis in carotid artery" /> documented
                                        by X-ray/MR/CT angio or Doppler.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Symptomatic <LabelWrapper label="PAD" /> defined as one of these:
                                <ol type="a">
                                    <li>
                                        Intermittent claudication with an{' '}
                                        <LabelWrapper label="ABI < = 0.90" /> at rest{' '}
                                    </li>
                                    <li>
                                        Intermittent claudication with{' '}
                                        <LabelWrapper label=">= 50% stenosis in peripheral artery" />{' '}
                                        (excluding carotid) documented by X-ray/MR/CT angio or Doppler{' '}
                                    </li>
                                    <li>
                                        Prior <LabelWrapper label="peripheral artery" /> (excluding carotid){' '}
                                        <LabelWrapper label="revascularisation" />
                                    </li>
                                    <li>
                                        {' '}
                                        <LabelWrapper label="Lower extremity amputation" /> at or above ankle
                                        due to atherosclerotic disease (excluding e.g. trauma or
                                        osteomyelitis).
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                ),
                observedValue: (
                    <ol style={{ paddingLeft: '1em' }}>
                        <li>Coronary heart disease - b. Prior coronary revascularisation - CATH completed</li>
                    </ol>
                ),
                date: <div>February 21, 2020</div>,
            },
        ],
        exclusionCriteriaData: [
            {
                criteria: <LabelWrapper label="Active infection." />,
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="MI, stroke" />, hospitalisation for{' '}
                        <LabelWrapper label="unstable angina pectoris" />, or <LabelWrapper label="TIA" />{' '}
                        within 60 days before randomisation.
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        Planned <LabelWrapper label="coronary, carotid" />, or{' '}
                        <LabelWrapper label="peripheral artery revascularisation" /> on randomisation day.
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        Major <LabelWrapper label="cardiac/non-cardiac surgical" />, or major{' '}
                        <LabelWrapper label="endoscopic procedure" /> in the last 60 days prior to
                        randomisation or any major surgical procedure planned at randomisation.
                    </>
                ),
                observedValue: <div>No</div>,
            },
        ],
    },
    {
        name: 'hermes',
        eligibility: 'ineligible',
        dateCreated: null,
        dateUpdated: null,
        inclusion: {
            flag: false,
            value: 6,
        },

        exclusion: {
            flag: true,
            value: 10,
        },
        id: 'he1',
        info: {
            title: 'A Research Study to Look at How Ziltivekimab Works Compared to Placebo in People With Heart Failure and Inflammation (HERMES).',
            sponsor: 'Novo Nordisk A/S',
            objective: 'If ziltivekimab can be used to treat people living with HF and inflammation.',
            studyArm: [
                'Experimental: Ziltivekimab 15mg (up to 4 years).',
                'Placebo Comparator: Placebo (up to 4 years).',
            ],
        },
        inclusionCriteriaData: [
            {
                criteria: (
                    <>
                        <LabelWrapper label="hs-CRP > = 2" /> mg/L at screening.
                    </>
                ),
                observedValue: <div>2 mg/L</div>,
                date: <div>Jul 21, 2022</div>,
            },
            {
                criteria: (
                    <>
                        At least one of the following:
                        <ol type="a">
                            <li>
                                <LabelWrapper label="NT-proBNP > = 300" /> pg/mL at screening{' '}
                                <LabelWrapper label="without ongoing AF. If ongoing AF" /> at screening,{' '}
                                <LabelWrapper label="NTproBNP > = 600" />
                                pg/mL.
                            </li>
                            <li>
                                Hospitalisation or urgent/unplanned visit with{' '}
                                <LabelWrapper label="decompensated HF" /> which required IV loop diuretic
                                treatment, within the last 9M prior to screening in combination with NT-proBNP{' '}
                                {'>='} 200 pg/mL at screening without ongoing AF. If ongoing AF at screening,
                                NT-proBNP must be {'>='}
                                600 pg/mL.
                            </li>
                        </ol>
                    </>
                ),
                observedValue: <div>350 pg/mL</div>,
                date: <div>Jul 21, 2022</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="HF diagnosis" /> NYHA Class II-IV.
                    </>
                ),
                observedValue: <div>Class I</div>,
                date: <div>Mar 25, 2023</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="LVEF > = 40 %" /> documented by echo within 12M prior to or at
                        screening. The LVEF must be documented in medical records and the most recent
                        measurement must be used to determine eligibility with no interim event signalling
                        potential deterioration in EF (e.g., MI or HF hospitalisation).
                    </>
                ),
                observedValue: <div>45.96%</div>,
                date: <div>May 16, 2022</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="Structural heart disease" /> and/or{' '}
                        <LabelWrapper label="functional heart disease" /> documented by echo within 12M prior
                        to or at screening showing at least one of the following:
                        <ol type="a">
                            <li>
                                <LabelWrapper label="LA volume index > 34" /> mL/m<sup>2</sup>.
                            </li>
                            <li>
                                <LabelWrapper label=" LA diameter >= 3.8" /> cm.
                            </li>
                            <li>
                                <LabelWrapper label="LA length >= 5.0" /> cm.
                            </li>
                            <li>
                                <LabelWrapper label="LA area >=  20" /> cm<sup>2</sup>.
                            </li>
                            <li>
                                <LabelWrapper label="LA volume >= 55" /> mL.
                            </li>
                            <li>
                                <LabelWrapper label="Intraventricular septal thickness >= 1.1" /> cm.
                            </li>
                            <li>
                                <LabelWrapper label="Posterior wall thickness > = 1.1" /> cm.
                            </li>
                            <li>
                                <LabelWrapper label="LV mass index > = 115" /> g⁄m<sup>2</sup> in{' '}
                                <LabelWrapper label="men" /> or <LabelWrapper label=">= 95" /> g{'⁄'}m
                                <sup>2</sup> in women.
                            </li>
                            <li>
                                <LabelWrapper label="E/e' > = 10." />
                            </li>
                            <li>
                                <LabelWrapper label="e' < 9 cm/s." />
                            </li>
                        </ol>
                    </>
                ),
                observedValue: (
                    <div>
                        <li>
                            LA volume index = 37.75 cm<sup>3</sup>/m<sup>2</sup>
                        </li>
                    </div>
                ),
                date: <div>May 16, 2022</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="No HF hospitalisations" /> or urgent HF visits between screening
                        and randomisation.
                    </>
                ),
                observedValue: <div>No</div>,
                date: <div>N/A</div>,
            },
        ],
        exclusionCriteriaData: [
            {
                criteria: (
                    <>
                        <LabelWrapper label="MI, stroke, unstable angina pectoris, TIA, or HF hospitalisation," />{' '}
                        within 30 days prior to screening.
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="Systolic BP > = 180" /> mmHg at screening. If the systolic BP is
                        160-179 mmHg, the patient should be receiving {'>='} 3 antihypertensive drugs.
                        (Retesting may occur within the visit window and without rescreening, at
                        investigator’s discretion).
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="HR > 110" /> or <LabelWrapper label="< 40" /> mBPM on ECG at
                        screening (Retesting may occur within the visit window and without rescreening, at
                        investigator’s discretion).
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        Planned{' '}
                        <LabelWrapper label="coronary, carotid or peripheral artery revascularisation" />{' '}
                        during screening period. (Except coronary angio).
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        Planned <LabelWrapper label="cardiac device" /> or{' '}
                        <LabelWrapper label="AF ablation" /> during screening period.
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        Major <LabelWrapper label="Scardiac/non-cardiac surgical" /> , or major{' '}
                        <LabelWrapper label="endoscopic procedure" /> within the past 60 days prior to
                        randomisation or any major surgical procedure planned at the time of randomisation.
                    </>
                ),
                observedValue: <div>No</div>,
            },

            {
                criteria: (
                    <>
                        <LabelWrapper label="HF" /> due to infiltrative cardiomyopathy (e.g., sarcoid,
                        amyloid), arrhythmogenic RV cardiomyopathy, Takutsubo CMD, genetic hypertrophic CMD or
                        obstructive CMD, active myocarditis, constrictive pericarditis, cardiac tamponade,
                        uncorrected more than moderate primary valve disease.
                    </>
                ),
                observedValue: <div>No</div>,
            },

            {
                criteria: (
                    <>
                        <LabelWrapper label="Primary pulmonary HTN, chronic pulmonary embolism, severe pulmonary disease" />{' '}
                        including COPD.
                    </>
                ),
                observedValue: <div>No</div>,
            },

            {
                criteria: (
                    <>
                        Any other condition judged by the investigator as explaining{' '}
                        <LabelWrapper label="HF symptoms" /> and <LabelWrapper label="signs" /> (e.g.,
                        anaemia, hypothyroidism).
                    </>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: (
                    <>
                        <LabelWrapper label="Active infection." />
                    </>
                ),
                observedValue: <div>No</div>,
            },
        ],
    },
    {
        name: 'oceanic',
        eligibility: 'declined',
        dateCreated: null,
        dateUpdated: null,
        inclusion: {
            flag: true,
            value: 2,
        },
        exclusion: {
            flag: true,
            value: 2,
        },
        id: 'oc1',
        info: {
            title: 'A Study to Learn How Well the Study Treatment Asundexian Works and How Safe it is Compared to Apixaban to Prevent Stroke or Systemic Embolism in People With Irregular and Often Rapid Heartbeat (Atrial Fibrillation), and at Risk for Stroke (OCEANIC-AF).',
            sponsor: 'Bayer',
            objective:
                'If asundexian works to prevent stroke and systemic embolism and if it is safe compared to apixaban in people with AF and at high risk for stroke.',
            studyArm: [
                'Experimental: Asundexian and Apixaban matching placebo.',
                'Active Comparator: Apixaban (2.5 or 5 mg) and Asundexian matching placebo.',
            ],
        },
        inclusionCriteriaData: [
            {
                criteria: (
                    <ol style={{ paddingLeft: '1rem' }}>
                        <li>AF w/ indication for indefinite treatment w/ anticoag </li>
                    </ol>
                ),
                observedValue: (
                    <ol type="a" style={{ paddingLeft: '1em' }}>
                        <li>AF - Apixaban </li>
                    </ol>
                ),
                date: <div>12-Mar-2019</div>,
            },
            {
                criteria: (
                    <ol start="2" style={{ paddingLeft: '1rem' }}>
                        <li>
                            CHA2DS2-VASc score <LabelWrapper label="≥ 3" /> if male or{' '}
                            <LabelWrapper label="≥ 4" /> if female OR CHA2DS2-VASc score of{' '}
                            <LabelWrapper
                                label="2(male) /
                            3(female)"
                            />{' '}
                            + enrichment criteria Enrichment ONLY for chadvasc{' '}
                            <LabelWrapper label="2(male) / 3(female)" />
                            <ol type="a">
                                <li>Age {'>'} 70 </li>
                                <li>Prior stroke/TIA, systemic embolism</li>
                                <li>eGFR {'<'}50 </li>
                                <li>Prior episode of non-traumatic bleeding </li>
                                <li>
                                    Current single agent antiplatelet therapy planned to continue for at least
                                    6 months after randomization{' '}
                                </li>
                                <li>
                                    ≤ 6 consecutive weeks of treatment with oral anticoagulant prior to
                                    randomization
                                </li>
                            </ol>
                        </li>
                    </ol>
                ),
                observedValue: <div>CHA2DS2-VASc = 3</div>,
                date: <div>22-Aug-2022</div>,
            },
        ],
        exclusionCriteriaData: [
            {
                criteria: (
                    <div>
                        Daily or near daily ({'>'} 5 days per week) therapy with nonsteroidal antiinflammatory
                        drugs (NSAIDs) for more than 4 weeks during the study period
                    </div>
                ),
                observedValue: <div>No</div>,
            },
            {
                criteria: <div>Known significant liver disease or eGFR {'<'} 25</div>,
                observedValue: <div>No</div>,
            },
        ],
    },
]
