export const GET_CLINIC_LIST_DATA = 'GET_CLINIC_LIST_DATA'
export const SET_CLINIC_LIST_LOADING = 'SET_CLINIC_LIST_LOADING'

export const GET_CLINICAL_TRIAL_LIST_DATA = 'GET_CLINICAL_TRIAL_LIST_DATA' //used
export const SET_CLINICAL_TRIAL_LIST_LOADING = 'SET_CLINICAL_TRIAL_LIST_LOADING' //used

export const GET_CLINICAL_TRIAL_DATA = 'GET_CLINICAL_TRIAL_DATA' //new
export const SET_CLINICAL_TRIAL_LOADING = 'SET_CLINICAL_TRIAL_LOADING' //new

export const GET_CLINICAL_TRIAL_CRITERIA_DATA = 'GET_CLINICAL_TRIAL_CRITERIA_DATA'
export const SET_CLINICAL_TRIAL_CRITERIA_LOADING = 'SET_CLINICAL_TRIAL_CRITERIA_LOADING'
export const CLEAR_CLINICAL_TRIAL_CRITERIA = 'CLEAR_CLINICAL_TRIAL_CRITERIA'
export const SET_CURRENT_CLINICAL_TRIAL = 'SET_CURRENT_CLINICAL_TRIAL'
export const CLEAR_CLINICAL_TRIAL = 'CLEAR_CLINICAL_TRIAL'
export const SET_ELIGIBILITY_CRITERIA = 'SET_ELIGIBILITY_CRITERIA'

/* matched and parial match patient */
export const GET_MATCHED_PATIENT_LIST = 'GET_MATCHED_PATIENT_LIST'
export const SET_MATCHED_PATIENT_LIST_LOADING = 'SET_MATCHED_PATIENT_LIST_LOADING'
export const SET_MATCHED_PATIENT_LIST_FAILED = 'SET_MATCHED_PATIENT_LIST_FAILED'
export const CLEAR_MATCHED_PATIENT = 'CLEAR_MATCHED_PATIENT'
export const CLEAR_PARTIALLY_MATCHED_PATIENT = 'CLEAR_PARTIALLY_MATCHED_PATIENT'
export const GET_PARTIALLY_MATCHED_PATIENT_LIST = 'GET_PARTIALLY_MATCHED_PATIENT_LIST'
export const SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING = 'SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING'
export const SET_PARTIALLY_MATCHED_PATIENT_LIST_FAILED = 'SET_PARTIALLY_MATCHED_PATIENT_LIST_FAILED'
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT'
export const CLEAR_PATIENT = 'CLEAR_PATIENT'
export const SET_PATIENT_CRITERIA = 'SET_PATIENT_CRITERIA'
export const SET_PATIENT_CRITERIA_LOADING = 'SET_PATIENT_CRITERIA_LOADING'

export const GET_GOLD_PATIENT_LIST = 'GET_GOLD_PATIENT_LIST'
export const SET_GOLD_PATIENT_LIST_LOADING = 'SET_GOLD_PATIENT_LIST_LOADING'
export const SET_GOLD_PATIENT_LIST_FAILED = 'SET_GOLD_PATIENT_LIST_FAILED'
export const CLEAR_GOLD_PATIENT = 'CLEAR_GOLD_PATIENT'

/*  download gold matches  pdf */
export const GET_GOLD_MATCHES_PDF = 'GET_GOLD_MATCHES_PDF'
export const SET_GOLD_MATCHES_PDF_LOADING = 'SET_GOLD_MATCHES_PDF_LOADING'
export const CLEAR_GOLD_MATCHES_PDF = 'CLEAR_GOLD_MATCHES_PDF'

export const GET_SILVER_PATIENT_LIST = 'GET_SILVER_PATIENT_LIST'
export const SET_SILVER_PATIENT_LIST_LOADING = 'SET_SILVER_PATIENT_LIST_LOADING'
export const SET_SILVER_PATIENT_LIST_FAILED = 'SET_SILVER_PATIENT_LIST_FAILED'
export const CLEAR_SILVER_PATIENT = 'CLEAR_SILVER_PATIENT'

/*  download silver matches pdf */
export const GET_SILVER_MATCHES_PDF = 'GET_SILVER_MATCHES_PDF'
export const SET_SILVER_MATCHES_PDF_LOADING = 'SET_SILVER_MATCHES_PDF_LOADING'
export const CLEAR_SILVER_MATCHES_PDF = 'CLEAR_SILVER_MATCHES_PDF'

export const GET_GOLD_PATIENT_SUMMARY = 'GET_GOLD_PATIENT_SUMMARY'
export const SET_GOLD_PATIENT_SUMMARY_LOADING = 'SET_GOLD_PATIENT_SUMMARY_LOADING'
export const CLEAR_GOLD_PATIENT_SUMMARY = 'CLEAR_GOLD_PATIENT_SUMMARY'

export const GET_SILVER_PATIENT_SUMMARY = 'GET_SILVER_PATIENT_SUMMARY'
export const SET_SILVER_PATIENT_SUMMARY_LOADING = 'SET_SILVER_PATIENT_SUMMARY_LOADING'
export const CLEAR_SILVER_PATIENT_SUMMARY = 'CLEAR_SILVER_PATIENT_SUMMARY'

/* matched and parial match trial count */
export const GET_MATCHED_PATIENT_TRIAL_COUNT = 'GET_MATCHED_PATIENT_TRIAL_COUNT'
export const SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING = 'SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING'
export const CLEAR_MATCHED_PATIENT_TRIAL_COUNT = 'CLEAR_MATCHED_PATIENT_TRIAL_COUNT'
export const GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT = 'GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT'
export const SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING =
  'SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING'
export const CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT = 'CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT'

/* upcoming appointment list for matches and paritally matched patient */
export const GET_UPCOMING_MATCHED_APPOINTMENT_LIST = 'GET_UPCOMING_MATCHED_APPOINTMENT_LIST'
export const GET_UPCOMING_PARTIALLY_MATCHED_APPOINTMENT_LIST =
  'GET_UPCOMING_PARTIALLY_MATCHED_APPOINTMENT_LIST'
export const GET_UPCOMING_MATCHED_APPOINTMENT_LIST_LOADING = 'GET_UPCOMING_MATCHED_APPOINTMENT_LIST_LOADING'
export const GET_UPCOMING_PARTIALLY_MATCHED_APPOINTMENT_LIST_LOADING =
  'GET_UPCOMING_PARTIALLY_MATCHED_APPOINTMENT_LIST_LOADING'

/* provider list  */
export const GET_PROVIDER_LIST = 'GET_PROVIDER_LIST'
export const SET_PROVIDER_LIST_LOADING = 'SET_PROVIDER_LIST_LOADING'
export const GET_PROVIDER_LIST_FAILED = 'GET_PROVIDER_LIST_FAILED'
export const SET_CURRENT_PROVIDERS = 'SET_CURRENT_PROVIDERS'
export const CLEAR_CURRENT_PROVIDERS = 'CLEAR_CURRENT_PROVIDERS'

/* upcoming patient appointment list */
export const SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA = 'SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA'
export const CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA = 'CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA'

/* patient info data */
export const SET_PATIENT_INFO_DATA = 'SET_PATIENT_INFO_DATA'
export const CLEAR_PATIENT_INFO_DATA = 'CLEAR_PATIENT_INFO_DATA'

/*  download matches pdf */
export const GET_MATCHES_PDF = 'GET_MATCHES_PDF'
export const SET_MATCHES_PDF_LOADING = 'SET_MATCHES_PDF_LOADING'
export const CLEAR_MATCHES_PDF = 'CLEAR_MATCHES_PDF'

/*  download partial matches pdf */
export const GET_PARTIAL_MATCHES_PDF = 'GET_PARTIAL_MATCHES_PDF'
export const SET_PARTIAL_MATCHES_PDF_LOADING = 'SET_PARTIAL_MATCHES_PDF_LOADING'
export const CLEAR_PARTIAL_MATCHES_PDF = 'CLEAR_PARTIAL_MATCHES_PDF'

/*  downlaod upcoming appointment pdf */
export const GET_APPOINTMENT_PDF = 'GET_APPOINTMENT_PDF'
export const SET_APPOINTMENT_PDF_LOADING = 'SET_APPOINTMENT_PDF_LOADING'
export const CLEAR_APPOINTMENT_PDF = 'CLEAR_APPOINTMENT_PDF'
