import {
  /* clinic list */
  GET_CLINIC_LIST_DATA,
  SET_CLINIC_LIST_LOADING,

  /* clinical trial list */
  GET_CLINICAL_TRIAL_LIST_DATA,
  SET_CLINICAL_TRIAL_LIST_LOADING,
  GET_CLINICAL_TRIAL_CRITERIA_DATA,
  SET_CLINICAL_TRIAL_CRITERIA_LOADING,
  SET_CURRENT_CLINICAL_TRIAL,
  SET_ELIGIBILITY_CRITERIA,
  CLEAR_CLINICAL_TRIAL,
  CLEAR_CLINICAL_TRIAL_CRITERIA,

  /* matched & patially matched */
  GET_MATCHED_PATIENT_LIST,
  GET_PARTIALLY_MATCHED_PATIENT_LIST,
  SET_MATCHED_PATIENT_LIST_LOADING,
  SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING,
  CLEAR_MATCHED_PATIENT,
  CLEAR_PARTIALLY_MATCHED_PATIENT,

  /* Gold Patient list */
  GET_GOLD_PATIENT_LIST,
  SET_GOLD_PATIENT_LIST_LOADING,
  CLEAR_GOLD_PATIENT,

  /* Gold matches pdf */
  GET_GOLD_MATCHES_PDF,
  SET_GOLD_MATCHES_PDF_LOADING,
  CLEAR_GOLD_MATCHES_PDF,

  /* Silver matches pdf */
  GET_SILVER_MATCHES_PDF,
  SET_SILVER_MATCHES_PDF_LOADING,
  CLEAR_SILVER_MATCHES_PDF,

  /* Silver Patient list */
  GET_SILVER_PATIENT_LIST,
  SET_SILVER_PATIENT_LIST_LOADING,
  CLEAR_SILVER_PATIENT,

  /* Gold Patient summary */
  GET_GOLD_PATIENT_SUMMARY,
  SET_GOLD_PATIENT_SUMMARY_LOADING,
  CLEAR_GOLD_PATIENT_SUMMARY,
  /* Silver patient summary */
  GET_SILVER_PATIENT_SUMMARY,
  SET_SILVER_PATIENT_SUMMARY_LOADING,
  CLEAR_SILVER_PATIENT_SUMMARY,

  /* provider list */
  GET_PROVIDER_LIST,
  SET_PROVIDER_LIST_LOADING,
  SET_CURRENT_PROVIDERS,

  /* Upcoming patient appointment list */
  SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,
  CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA,

  /* patient info data */
  SET_PATIENT_INFO_DATA,
  CLEAR_PATIENT_INFO_DATA,

  /* download pdf */
  GET_MATCHES_PDF,
  SET_MATCHES_PDF_LOADING,
  CLEAR_MATCHES_PDF,

  /* partial matched pdf download */
  SET_PARTIAL_MATCHES_PDF_LOADING,
  GET_PARTIAL_MATCHES_PDF,
  CLEAR_PARTIAL_MATCHES_PDF,

  /* download matches appointment pdf */
  GET_APPOINTMENT_PDF,
  SET_APPOINTMENT_PDF_LOADING,
  CLEAR_APPOINTMENT_PDF,

  /* matched and parial match trial count */
  GET_MATCHED_PATIENT_TRIAL_COUNT,
  SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  CLEAR_MATCHED_PATIENT_TRIAL_COUNT,
  GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
  SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING,
  CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT,
} from './screenFlow.type'

const INITIAL_STATE = {
  /* clinic list */
  clinicList: [],
  clinicListLoading: false,

  /* clinical trial list */
  currentClinicalTrial: null,
  eligibilityCriteriaDetails: null,
  clinicalTrialList: [],
  clinicalTrialCriteria: [],
  clinicalTrialListLoading: false,
  clinicalTrialCriteriaLoading: false,
  lud: '',

  /* matched & patially matched */
  matchedPatientList: [],
  partiallyMatchedPatientList: [],
  matchedPatientListLoading: false,
  partiallyMatchedPatientListLoading: false,

  /* gold list */
  goldPatientList: [],
  goldPatientListLoading: false,

  /* silver list */
  silverPatientList: [],
  silverPatientListLoading: false,

  /* gold list summary */
  goldPatientSummary: [],
  goldPatientSummaryLoading: false,

  /* silver list summary */
  silverPatientSummary: [],
  silverPatientSummaryLoading: false,

  /*  provider */
  providerList: [],
  providerListLoading: false,
  currentProviders: [],

  /* upcoming patient appointment list */
  upcomingPatientAppointmentListData: null,

  /* patient info data */
  patientInfoData: null,

  /* download pdf */
  matchesPdf: null,
  matchesPdfLoading: false,

  /* partial matched pdf download */
  partialMatchesPdf: null,
  partialMatchesPdfLoading: false,

  /* download matches appointment pdf */
  appointmentPdf: null,
  appointmentPdfLoading: false,

  /* matched and parial match trial count */
  matchedPatientTrialCount: null,
  matchedPatientTrialCountLoading: false,
  partiallyMatchedTrialCount: null,
  partiallyMatchedTrialCountLoading: false,

  /* gold list pdf */
  goldMatchesPdf: null,
  goldMatchesPdfLoading: false,

  /* silver list pdf */
  silverMatchesPdf: null,
  silverMatchesPdfLoading: false,
}

const getLastUpdatedDate = (payload) =>
  payload?.hasOwnProperty('lastUpdatedDate') ? payload?.lastUpdatedDate : ''

const screenFlowPortalClinicalTrialReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_CLINICAL_TRIAL:
      return {
        ...INITIAL_STATE,
      }

    case SET_ELIGIBILITY_CRITERIA:
      return {
        ...state,
        eligibilityCriteriaDetails: action?.payload,
      }

    case SET_CURRENT_CLINICAL_TRIAL:
      return {
        ...state,
        currentClinicalTrial: action?.payload,
      }

    case GET_CLINIC_LIST_DATA:
      return {
        ...state,
        clinicList: action?.payload,
        lud: getLastUpdatedDate(action.payload),
      }

    case SET_CLINIC_LIST_LOADING:
      return {
        ...state,
        clinicListLoading: action.payload,
      }

    case GET_CLINICAL_TRIAL_LIST_DATA:
      return {
        ...state,
        clinicalTrialList: action?.payload,
        lud: getLastUpdatedDate(action.payload),
      }

    case GET_CLINICAL_TRIAL_CRITERIA_DATA:
      return {
        ...state,
        clinicalTrialCriteria: action?.payload,
      }

    case SET_CLINICAL_TRIAL_LIST_LOADING:
      return {
        ...state,
        clinicalTrialListLoading: action.payload,
      }

    case SET_CLINICAL_TRIAL_CRITERIA_LOADING:
      return {
        ...state,
        clinicalTrialCriteriaLoading: action.payload,
      }

    case CLEAR_CLINICAL_TRIAL_CRITERIA:
      return {
        ...state,
        clinicalTrialCriteria: [],
      }

    /* matched & patially matched */
    case GET_MATCHED_PATIENT_LIST:
      return {
        ...state,
        matchedPatientList: action?.payload,
      }
    case GET_PARTIALLY_MATCHED_PATIENT_LIST:
      return {
        ...state,
        partiallyMatchedPatientList: action?.payload,
      }
    case SET_MATCHED_PATIENT_LIST_LOADING:
      return {
        ...state,
        matchedPatientListLoading: action.payload,
      }
    case SET_PARTIALLY_MATCHED_PATIENT_LIST_LOADING:
      return {
        ...state,
        partiallyMatchedPatientListLoading: action.payload,
      }
    case CLEAR_MATCHED_PATIENT:
      return {
        ...state,
        matchedPatientList: [],
      }
    case CLEAR_PARTIALLY_MATCHED_PATIENT:
      return {
        ...state,
        partiallyMatchedPatientList: [],
      }

    /* Gold Patient list */
    case GET_GOLD_PATIENT_LIST:
      return {
        ...state,
        goldPatientList: action?.payload,
      }
    case SET_GOLD_PATIENT_LIST_LOADING:
      return {
        ...state,
        goldPatientListLoading: action.payload,
      }
    case CLEAR_GOLD_PATIENT:
      return {
        ...state,
        goldPatientList: [],
      }

    /* gold list pdf */
    case GET_GOLD_MATCHES_PDF:
      return {
        ...state,
        goldMatchesPdf: action?.payload,
      }
    case SET_GOLD_MATCHES_PDF_LOADING:
      return {
        ...state,
        goldMatchesPdfLoading: action.payload,
      }
    case CLEAR_GOLD_MATCHES_PDF:
      return {
        ...state,
        goldMatchesPdf: null,
      }

    /* Silver Patient List */
    case GET_SILVER_PATIENT_LIST:
      return {
        ...state,
        silverPatientList: action?.payload,
      }
    case SET_SILVER_PATIENT_LIST_LOADING:
      return {
        ...state,
        silverPatientListLoading: action.payload,
      }
    case CLEAR_SILVER_PATIENT:
      return {
        ...state,
        silverPatientList: [],
      }

    /* silver list pdf */
    case GET_SILVER_MATCHES_PDF:
      return {
        ...state,
        silverMatchesPdf: action?.payload,
      }
    case SET_SILVER_MATCHES_PDF_LOADING:
      return {
        ...state,
        silverMatchesPdfLoading: action.payload,
      }
    case CLEAR_SILVER_MATCHES_PDF:
      return {
        ...state,
        silverMatchesPdf: null,
      }

    /* Gold Patient summary */
    case GET_GOLD_PATIENT_SUMMARY:
      return {
        ...state,
        goldPatientSummary: action?.payload,
      }
    case SET_GOLD_PATIENT_SUMMARY_LOADING:
      return {
        ...state,
        goldPatientSummaryLoading: action.payload,
      }
    case CLEAR_GOLD_PATIENT_SUMMARY:
      return {
        ...state,
        goldPatientSummary: [],
      }

    /* Silver Patient summary */
    case GET_SILVER_PATIENT_SUMMARY:
      return {
        ...state,
        silverPatientSummary: action?.payload,
      }
    case SET_SILVER_PATIENT_SUMMARY_LOADING:
      return {
        ...state,
        silverPatientSummaryLoading: action.payload,
      }
    case CLEAR_SILVER_PATIENT_SUMMARY:
      return {
        ...state,
        silverPatientSummary: [],
      }

    /* provider list */
    case GET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action?.payload,
      }
    case SET_PROVIDER_LIST_LOADING:
      return {
        ...state,
        providerListLoading: action.payload,
      }

    case SET_CURRENT_PROVIDERS:
      return {
        ...state,
        currentProviders: action?.payload,
      }

    /* upcoming patient appointment list */
    case SET_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA:
      return {
        ...state,
        upcomingPatientAppointmentListData: action?.payload,
      }
    case CLEAR_UPCOMING_PATIENT_APPOINTMENT_LIST_DATA:
      return {
        ...state,
        upcomingPatientAppointmentListData: null,
      }

    /*   patient info data */
    case SET_PATIENT_INFO_DATA:
      return {
        ...state,
        patientInfoData: action?.payload,
      }
    case CLEAR_PATIENT_INFO_DATA:
      return {
        ...state,
        patientInfoData: null,
      }

    /* download pdf */
    case GET_MATCHES_PDF:
      return {
        ...state,
        matchesPdf: action?.payload,
      }
    case SET_MATCHES_PDF_LOADING:
      return {
        ...state,
        matchesPdfLoading: action.payload,
      }
    case CLEAR_MATCHES_PDF:
      return {
        ...state,
        matchesPdf: null,
      }

    /* partial matched pdf download */
    case GET_PARTIAL_MATCHES_PDF:
      return {
        ...state,
        partialMatchesPdf: action?.payload,
      }
    case SET_PARTIAL_MATCHES_PDF_LOADING:
      return {
        ...state,
        partialMatchesPdfLoading: action.payload,
      }
    case CLEAR_PARTIAL_MATCHES_PDF:
      return {
        ...state,
        partialMatchesPdf: null,
      }

    /* download matches appointment pdf */
    case GET_APPOINTMENT_PDF:
      return {
        ...state,
        appointmentPdf: action?.payload,
      }
    case SET_APPOINTMENT_PDF_LOADING:
      return {
        ...state,
        appointmentPdfLoading: action.payload,
      }
    case CLEAR_APPOINTMENT_PDF:
      return {
        ...state,
        appointmentPdf: null,
      }

    /* matched and parial match trial count */
    case GET_MATCHED_PATIENT_TRIAL_COUNT:
      return {
        ...state,
        matchedPatientTrialCount: action?.payload,
      }
    case SET_MATCHED_PATIENT_TRIAL_COUNT_LOADING:
      return {
        ...state,
        matchedPatientTrialCountLoading: action.payload,
      }

    case CLEAR_MATCHED_PATIENT_TRIAL_COUNT:
      return {
        ...state,
        matchedPatientTrialCount: null,
      }

    case GET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT:
      return {
        ...state,
        partiallyMatchedTrialCount: action?.payload,
      }
    case SET_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT_LOADING:
      return {
        ...state,
        partiallyMatchedTrialCountLoading: action.payload,
      }

    case CLEAR_PARTIALLY_MATCHED_PATIENT_TRIAL_COUNT:
      return {
        ...state,
        partiallyMatchedTrialCount: null,
      }

    default:
      return state
  }
}

export default screenFlowPortalClinicalTrialReducer
