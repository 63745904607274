import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session'
import toggleModal from './modals/toggleModal.reducer'
import Loginreducer from './auth/auth.reducer'
import appointmentReducer from './appointment/appointment.reducer'
import patientReducer from './patient/patient.reducer'
import vitalReducer from './vitals/vitals.reducer'
import alertReducer from './alert/alert.reducer'
import vitalListReducer from './vitalList/vitalList.reducer'
import labsReducer from './labs/labs.reducer'
import labsListReducer from './labsList/labsList.reducer'
import ecgReducer from './ECH/ecg/ecg.reducer'
import ecgListReducer from './ECH/ecgList/ecgList.reducer'
import holterReducer from './ECH/Holter/holter.reducer'
import holterListsReducer from './ECH/holterList/holterList.reducer'
import gxtReducer from './ECH/GXT/gxt.reducer'
import gxtListsReducer from './ECH/gxtList/gxtList.reducer'
import echoReducer from './ESN/echo/echo.reducer'
import echoListReducer from './ESN/echoList/echoList.reducer'
import stressEchoListReducer from './ESN/stressEchoList/stressEchoList.reducer'
import nuclearListReducer from './ESN/nuclearList/nuclearList.reducer'
import cathListReducer from './CCM/cathList/cathList.reducer'
import CTListReducer from './CCM/CTList/CTList.reducer'
import MRIListReducer from './CCM/MRIList/MRIList.reducer'
import cardiacReducer from './COP/cardiac/cardiac.reducer'
import cardiacListReducer from './COP/cardiacList/cardiacList.reducer'
import socialListReducer from './socialList/socialList.reducer'
import familyListReducer from './familyList/familyList.reducer'
import consultNotesReducer from './consultNotes/consultNotes.reducer'
import adverseEventReducer from './adverseEvent/adverseEvent.reducer'
import intervalListReducer from './intervalHistory/intervalHistory.reducer'
import physicalListReducer from './physicalExam/physicalExam.reducer'
import medicalHistoryReducer from './medicalHistory/medicalHistory.reducer'
import cardiacHistoryReducer from './cardiacHistory/cardiacHistory.reducer'
import recommendationListReducer from './recommendations/recommendations.reducer'
import screenFlowListReducer from './screenFlow/screenFlow.reducer'
import screenFlowPortalClinicalTrialReducer from './searchFlow/screenFlow/screenFlow.reducer'
import cohortBuilderReducer from './searchFlow/cohortBuilder/cohortBuilder.reducer'

const rootReducer = combineReducers({
    toggleModals: toggleModal,
    loginData: Loginreducer,
    session: sessionReducer,
    appointment: appointmentReducer,
    patient: patientReducer,
    vital: vitalReducer,
    vitalList: vitalListReducer,
    labs: labsReducer,
    labsList: labsListReducer,
    ecg: ecgReducer,
    ecgLists: ecgListReducer,
    alert: alertReducer,
    holter: holterReducer,
    holterList: holterListsReducer,
    gxt: gxtReducer,
    gxtList: gxtListsReducer,
    echo: echoReducer,
    echoList: echoListReducer,
    stressEcho: stressEchoListReducer,
    nuclear: nuclearListReducer,
    cath: cathListReducer,
    CT: CTListReducer,
    MRI: MRIListReducer,
    cardiac: cardiacReducer,
    cardiacList: cardiacListReducer,
    socialList: socialListReducer,
    familyHistory: familyListReducer,
    consultNotes: consultNotesReducer,
    adverseEvent: adverseEventReducer,
    intervalHistory: intervalListReducer,
    physicalExam: physicalListReducer,
    medicalHistory: medicalHistoryReducer,
    cardiacHistory: cardiacHistoryReducer,
    recommendations: recommendationListReducer,
    screenFlow: screenFlowListReducer,
    screenFlowPortalClinicalTrial: screenFlowPortalClinicalTrialReducer,
    cohortBuilder: cohortBuilderReducer,
})

export default rootReducer
